import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TableRow, TableCell, FormControlLabel, Checkbox, Collapse, IconButton } from "@mui/material";
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

// Import Components
import TableComponent from './index.jsx';
import TableBodyCellComponent from './tableBodyCell.jsx';

// Import Images
import { ChevDownIcon, ChevUpIcon } from '../../assets/svg/index.js';

const TableBodyRowComponent = (props) => {
    /**
     * Define Props
     */
    const { classes, headers, data, parentDetail, rowIndex, haveCheckBox, haveSubTable, subTable, onClickCheckbox, onClickAccordian,
        subTableColSpan, selectComponentList, subTableHaveCheckbox, haveSubTableHeader, subTableActions, subTableIsAddData, onSubTableActions,
        onSubTableCompnentEvent, onSubTableHandleChipAdd, onSubTableHandleChipDelete, highlightIndex, ...rest
    } = props;

    /**
     * Define States
     */
    const [open, setOpen] = useState(false);

    /**
     * Toggle Accordian
     * @param {*} event
     * @param {*} toggle
     */
    const toggleAccordian = (event, toggle) => {
        event.preventDefault();
        if (haveSubTable && toggle) {
            onClickAccordian(data, !open);
            setOpen(!open);
        }
    };

    /**
     * Handle CheckBox
     * @param {*} event
     * @param {*} data
     */
    const handleCheckBoxClick = (event, data) => {
        onClickCheckbox(event.target.checked, data, parentDetail, false);
    };

    return (
        <React.Fragment>
            <TableRow key={`tableBodyRow-${rowIndex}`} className={`${classes.highlightRow} ${rowIndex === highlightIndex ? data?.status?.toLowerCase() : ''}`}>
                {
                    haveCheckBox &&
                    <TableCell key={`tableRowCheckbox-${rowIndex}`} className={"tableChecbox"}>
                        <FormControlLabel control={<Checkbox checked={data.is_selected || false} onChange={(event) => handleCheckBoxClick(event, data)} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />} />
                    </TableCell>
                }
                {
                    headers && headers.map((header, index) =>
                        <TableBodyCellComponent
                            key={`cell_${index}`}
                            header={header}
                            data={data}
                            selectComponentList={selectComponentList}
                            parentDetail={parentDetail}
                            classes={classes}
                            {...rest}
                        />
                    )
                }
                {
                    haveSubTable &&
                    <TableCell key={'accordian-cell'}>
                        {
                            open ?
                                <IconButton
                                    key={`icon-up-${rowIndex}`}
                                    aria-label="Up"
                                    color="inherit"
                                    className={'p-1 collapseIcon'}
                                    onClick={(event) => toggleAccordian(event, true)}
                                >
                                    <ChevUpIcon key={`up-${rowIndex}`} />
                                </IconButton>
                                :
                                <IconButton
                                    key={`icon-down-${rowIndex}`}
                                    aria-label="Down"
                                    color="inherit"
                                    className={'p-1 collapseIcon'}
                                    onClick={(event) => toggleAccordian(event, true)}
                                >
                                    <ChevDownIcon key={`down-${rowIndex}`} />
                                </IconButton>
                        }
                    </TableCell>
                }
            </TableRow>
            {
                haveSubTable &&
                <TableRow className={classes.accordionTableRow}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={subTableColSpan}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <TableComponent
                                {...rest}
                                headers={subTable.headers}
                                haveCheckBox={subTableHaveCheckbox}
                                defaultHeaderCheckBoxValue={data[subTable.rows] && data[subTable.rows].filter((item) => item.is_selected).length > 0 || false}
                                data={data[subTable.rows] || []}
                                parentDetail={data.id || ''}
                                onClickCheckbox={onClickCheckbox}
                                haveSubTableHeader={haveSubTableHeader}
                                actions={subTableActions}
                                isAdd={subTableIsAddData}
                                onCompnentEvent={onSubTableCompnentEvent}
                                onHandleChipAdd={onSubTableHandleChipAdd}
                                onHandleChipDelete={onSubTableHandleChipDelete}
                                selectComponentList={selectComponentList}
                                onClickActions={onSubTableActions}
                                isLoading={data.isLoading}
                                height={subTable.height}
                            />
                        </Collapse>
                    </TableCell>
                </TableRow>
            }
        </React.Fragment>
    );
};

/**
 * Set Component Props
 */
TableBodyRowComponent.propTypes = {
    classes: PropTypes.object,
    headers: PropTypes.array,
    data: PropTypes.object,
    parentDetail: PropTypes.string,
    rowIndex: PropTypes.number,
    haveCheckBox: PropTypes.bool,
    actions: PropTypes.array,
    haveSubTable: PropTypes.bool,
    subTable: PropTypes.object,
    subTableColSpan: PropTypes.number,
    onClickCheckbox: PropTypes.func,
    onClickActions: PropTypes.func,
    onClickAccordian: PropTypes.func,
    onCompnentEvent: PropTypes.func,
    conditionDeleteShowColumn: PropTypes.string,
    onHandleChipAdd: PropTypes.func,
    onHandleChipDelete: PropTypes.func,
    onHandleChipSelect: PropTypes.func,
    chipClassName: PropTypes.string,
    selectComponentList: PropTypes.object,
    subTableHaveCheckbox: PropTypes.bool,
    haveSubTableHeader: PropTypes.bool,
    subTableActions: PropTypes.array,
    subTableIsAddData: PropTypes.bool,
    onSubTableActions: PropTypes.func,
    onSubTableCompnentEvent: PropTypes.func,
    onSubTableHandleChipAdd: PropTypes.func,
    onSubTableHandleChipDelete: PropTypes.func,
    highlightIndex: PropTypes.number
};


/**
 * Set Default Values
 */
TableBodyRowComponent.defaultProps = {
    classes: {},
    headers: [],
    data: {},
    parentDetail: "",
    rowIndex: 0,
    haveCheckBox: false,
    actions: [],
    haveSubTable: false,
    subTable: {},
    subTableColSpan: 0,
    onClickCheckbox: () => { },
    onClickActions: () => { },
    onClickAccordian: () => { },
    onCompnentEvent: () => { },
    conditionDeleteShowColumn: "",
    onHandleChipAdd: () => { },
    onHandleChipDelete: () => { },
    onHandleChipSelect: () => { },
    selectComponentList: {},
    subTableHaveCheckbox: false,
    haveSubTableHeader: false,
    subTableActions: [],
    subTableIsAddData: false,
    onSubTableActions: () => { },
    onSubTableCompnentEvent: () => { },
    onSubTableHandleChipAdd: () => { },
    onSubTableHandleChipDelete: () => { },
    highlightIndex: -1
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data && prevProps.headers && nextProps.headers);
}

export default React.memo(TableBodyRowComponent, areEqual);