import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

//  Import Components
import { Grid, IconButton, Typography } from '@mui/material';
import Profile from './components/profile.jsx';

//  Import Styles
import style from './style.jsx';
import LayoutStyle from '../../layouts/style.jsx';

// Import Images
import DefaultLogo from '../../assets/img/dq_logo.png';

// Import Reducer
import { navigate } from '../../redux/reducer/navigationReducer';


const HeaderComponent = (props) => {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const reactNavigation = useNavigate();

    /**
     * Navigate to Home Page
     */
    const navigateHome = () => {
        dispatch(navigate({ path: 'home.root', state: {}, params: [] }));
    };

    return (
        <Grid item xs={12}>
            <Grid
                container
                className={classes.navbar}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item className={classes.logoSection}>
                    <React.Fragment>
                        <Grid className={classes.logoSectionDiv}>
                            <Grid onClick={() => navigateHome()}>
                                {/* <img src={(logo || DefaultLogo)} className={`${classes.appLogo} appLogo`} alt="app logo" /> */}
                                <img src={(DefaultLogo)} className={`${classes.appLogo} appLogo`} alt="app logo" />
                            </Grid>
                            <IconButton className={`${classes.backNavigation} backNavigation`} onClick={() => reactNavigation(-1)}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        </Grid>
                        <Typography variant="h2" className={classes.pageTitle}>
                            DQLabs Admin Portal
                        </Typography>
                    </React.Fragment>
                </Grid>
                <Grid item className="navRightside">
                    <Profile classes={classes} />
                </Grid>
            </Grid>
        </Grid>
    );
};

// default props
HeaderComponent.defaultProps = {
    classes: {}
};

// prop types
HeaderComponent.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...style(theme),
        ...LayoutStyle(theme)
    }),
    { withTheme: true }
)(HeaderComponent);