const style = (theme) => ({
    loaderContainer: {
        height: '100%',
        alignItems: 'center',
        alignContent: 'baseline'
        // background: '#fff'
    },
    circularContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    },
    attachmentContainer: {
        display: 'flex',
        flexDirection: 'column !important',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        opacity: '0.6'
    },
    attachmentUpoadIcon: {
        fill: `${theme.palette.greyshades.darkgrey} !important`,
        animation: 'attachmentAnim 2s linear infinite',
        width: '36px !important',
        height: '36px !important',
        marginBottom: 5
    }
});
export default style;