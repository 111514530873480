import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import classNames from 'classnames';

//  Import Styles
import NoResultStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

function LoaderButtonComponent(props) {

    /**
     * Define Props
     */
    const { classes, variant, color, isCircleLoader, size, isLoading, onClick, children, type, disabled } = props;

    return (
        <LoadingButton
            type={type}
            className={classNames(classes.loadingBtn, isCircleLoader ? classes.circleLoader : null)}
            variant={variant}
            size={size}
            color={color}
            loading={isLoading}
            disableElevation
            disabled={disabled || isLoading}
            onClick={onClick}
        >
            <span className="btnLabel">
                {children}
            </span>
        </LoadingButton>
    );
}

// default props
LoaderButtonComponent.defaultProps = {
    classes: {},
    children: PropTypes.string,
    variant: "contained",
    size: "small",
    color: "primary",
    isLoading: false,
    isCircleLoader: false,
    type: 'submit',
    disabled: false,
    onClick: () => { }
};

// prop types
LoaderButtonComponent.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    isLoading: PropTypes.bool,
    isCircleLoader: PropTypes.bool,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...NoResultStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(LoaderButtonComponent);