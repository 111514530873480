import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

// Import Styles
import Styles from './style.jsx';
import { Typography } from '@mui/material';
import appConstants from '../../constants/appConstants.js';

function AuthLayoutComponent({ component: Component, ...props }) {
    const { classes } = props;

    return (
        /* Authentication container */
        <Grid container>
            <Grid container className={classes.loginContainer}>
                <Grid item xs={8} className={classes.loginSlider}>
                    <Grid className={`${classes.slider} slider`}>
                        <img src={require('../../assets/img/banner/banner1.png')} alt="" />
                    </Grid>
                </Grid>
                <Grid item xs={4} className={classes.loginFormContainer}>
                    <Component />
                    <Grid item className="versionContainer">
                        <Typography variant="body2" className={classes.versiontext}>
                            {appConstants.labels.authentication.copyrights}
                            <br />
                            {' '}
                            Version: 2.0(S) 2.0(C) 2.0(A) 2022-04-29
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

AuthLayoutComponent.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.elementType
};

export const AuthLayout = withStyles(Styles)(AuthLayoutComponent);