import { combineEpics } from 'redux-observable';

import { authEpic } from './authEpic';
import { organizationEpic } from './organizationEpic';
import { defaultEpic } from './defaultEpic';


const rootEpic = combineEpics(
    ...authEpic,
    ...organizationEpic,
    ...defaultEpic
);

export default rootEpic;