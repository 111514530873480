import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { logger } from 'redux-logger';

// Import reducers and epics
import rootEpic from './epic';
import rootReducer from './reducer';

const epicMiddleware = createEpicMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [epicMiddleware, logger]
});

epicMiddleware.run(rootEpic);

export default store;