import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { ThemeProvider } from "@mui/material/styles";

// Routes
import Routes from "../config/routeConfig.jsx";

// Import Theme
import { getTheme } from "../assets/theme";

//Components
import AlertComponent from "../components/alert/index.jsx";

function App() {

  /**
   * Define Props
   */
  const dispatch = useDispatch();

  /**
   * Set State
   */
  const [theme, setTheme] = useState(getTheme());

  /**
   * Dispatch theme detail request
   */
  useEffect(() => {
      setTheme(getTheme({}));
  }, [dispatch]);


  return (
    <ThemeProvider theme={theme}>
      <Routes />
      <AlertComponent />
    </ThemeProvider>
  );
}

export default App;