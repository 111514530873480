import { combineReducers } from 'redux';

import alertReducer from './alertReducer';
import navigationReducer from './navigationReducer';
import authReducer from './authReducer';
import organizationReducer from './organizationReducer';
import defaultReducer from './defaultReducer';

const MainReducer = combineReducers({
    alert: alertReducer,
    navigation: navigationReducer,
    auth: authReducer,
    organization: organizationReducer,
    default: defaultReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'auth/logoutSuccess') {
        state = undefined;
    }
    return MainReducer(state, action);
};
export default rootReducer;