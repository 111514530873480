
export const typography = (palette) => {
  return {
    h1: {
      fontFamily: palette.headers.h1.fontFamily,
      fontSize: palette.headers.h1.size,
      fontWeight: palette.headers.h1.weight,
      fontStyle: palette.headers.h1.style,
      textTransform: palette.headers.h1.transform,
      textDecoration: palette.headers.h1.textDecoration,
      color: palette.headers.h1.color,
      lineHeight: 1
    },
    h2: {
      fontFamily: palette.headers.h2.fontFamily,
      fontSize: palette.headers.h2.size,
      fontWeight: palette.headers.h2.weight,
      fontStyle: palette.headers.h2.style,
      textTransform: palette.headers.h2.transform,
      textDecoration: palette.headers.h2.textDecoration,
      color: palette.headers.h2.color,
      lineHeight: 1
    },
    h3: {
      fontFamily: palette.headers.h3.fontFamily,
      fontSize: palette.headers.h3.size,
      fontWeight: palette.headers.h3.weight,
      fontStyle: palette.headers.h3.style,
      textTransform: palette.headers.h3.transform,
      textDecoration: palette.headers.h3.textDecoration,
      color: palette.headers.h3.color,
      lineHeight: 1.04
    },
    h4: {
      fontFamily: palette.headers.h4.fontFamily,
      fontSize: palette.headers.h4.size,
      fontWeight: palette.headers.h4.weight,
      fontStyle: palette.headers.h4.style,
      textTransform: palette.headers.h4.transform,
      textDecoration: palette.headers.h4.textDecoration,
      color: palette.headers.h4.color,
      lineHeight: 1.17
    },
    h5: {
      fontFamily: palette.headers.h5.fontFamily,
      fontSize: palette.headers.h5.size,
      fontWeight: palette.headers.h5.weight,
      fontStyle: palette.headers.h5.style,
      textTransform: palette.headers.h5.transform,
      textDecoration: palette.headers.h5.textDecoration,
      color: palette.headers.h5.color,
      lineHeight: 1.33
    },
    h6: {
      fontFamily: palette.headers.h6.fontFamily,
      fontSize: palette.headers.h6.size,
      fontWeight: palette.headers.h6.weight,
      fontStyle: palette.headers.h6.style,
      textTransform: palette.headers.h6.transform,
      textDecoration: palette.headers.h6.textDecoration,
      color: palette.headers.h6.color,
      lineHeight: 1.6
    },
    subtitle1: {
      fontFamily: palette.headers.subtitle1.fontFamily,
      fontSize: palette.headers.subtitle1.size,
      fontWeight: palette.headers.subtitle1.weight,
      fontStyle: palette.headers.subtitle1.style,
      textTransform: palette.headers.subtitle1.transform,
      textDecoration: palette.headers.subtitle1.textDecoration,
      color: palette.headers.subtitle1.color
    },
    subtitle2: {
      fontFamily: palette.headers.subtitle2.fontFamily,
      fontSize: palette.headers.subtitle2.size,
      fontWeight: palette.headers.subtitle2.weight,
      fontStyle: palette.headers.subtitle2.style,
      textTransform: palette.headers.subtitle2.transform,
      textDecoration: palette.headers.subtitle2.textDecoration,
      color: palette.headers.subtitle2.color
    },
    body1: {
      fontFamily: palette.headers.body1.fontFamily,
      fontSize: palette.headers.body1.size,
      fontWeight: palette.headers.body1.weight,
      fontStyle: palette.headers.body1.style,
      textTransform: palette.headers.body1.transform,
      textDecoration: palette.headers.body1.textDecoration,
      color: palette.headers.body1.color
    },
    body2: {
      fontFamily: palette.headers.body2.fontFamily,
      fontSize: palette.headers.body2.size,
      fontWeight: palette.headers.body2.weight,
      fontStyle: palette.headers.body2.style,
      textTransform: palette.headers.body2.transform,
      textDecoration: palette.headers.body2.textDecoration,
      color: palette.headers.body2.color,
      lineHeight: 1.5
    },
    button: {
      fontFamily: palette.headers.primaryButton.fontFamily,
      fontSize: palette.headers.primaryButton.size,
      fontWeight: palette.headers.primaryButton.weight,
      fontStyle: palette.headers.primaryButton.style,
      textTransform: palette.headers.primaryButton.transform,
      textDecoration: palette.headers.primaryButton.textDecoration,
      color: palette.headers.primaryButton.color
    },
    caption: {
      fontFamily: palette.headers.caption.fontFamily,
      fontSize: palette.headers.caption.size,
      fontWeight: palette.headers.caption.weight,
      fontStyle: palette.headers.caption.style,
      textTransform: palette.headers.caption.transform,
      textDecoration: palette.headers.caption.textDecoration,
      color: palette.headers.caption.color
    },
    overline: {
      fontFamily: palette.headers.overline.fontFamily,
      fontSize: palette.headers.overline.size,
      fontWeight: palette.headers.overline.weight,
      fontStyle: palette.headers.overline.style,
      textTransform: palette.headers.overline.transform,
      textDecoration: palette.headers.overline.textDecoration,
      color: palette.headers.overline.color
    }
  };
};