import LoginBg from '../../assets/img/loginbgpattern.png';

const AuthStyles = (theme) => ({
    loginContainer: {
        height: '100vh',
        overflowY: 'hidden',
        background: 'linear-gradient(112deg, #fff 0%, #f38787 30%)',
        '&:before': {
            background: `url(${LoginBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'bottom right',
            content: "' ' !important",
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: 0.5
        }
    },
    loginFormContainer: {
            position: 'relative'
    },
    loginSlider: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        background: '#fff',
        '& .slider': {
            '& img': {
                padding: '0 175px 0 100px',
                maxWidth: 'calc(100% - 300px)'
            },
            '& .sliderGrid': {
                padding: '0 175px 0 100px'
            },
            '& .slick-slider': {
            },
            '& .slickImages': {
                width: '100%',
                objectFit: 'contain'
            },
            '& .slick-active,slick-current .slickImages': {
            },
            '@media only screen and (min-width: 1600px)': {
                '& .sliderContent': {
                    maxWidth: '80%',
                    margin: 'auto auto 40px auto'
                }
            },
            '& .slick-dots': {
                bottom: '30px !important',
                transform: 'translate(-80%, 0%)',
                left: '50%',
                width: '50%'
            }
        }
    },
    versiontext: {
        position: 'fixed',
        bottom: '36px',
        right: '100px',
        color: '#fff !important',
        maxWidth: '240px',
        textAlign: 'center',
        fontWeight: '500'
    },
    slider: {
        background: '#fff',
        width: '100%',
        '& .slider-item': {
            '&:focus-visible': {
                outline: 'none !important'
            }
        },
        '& .slick-dots li button:before': {
            fontSize: '12px',
            color: '#ECEFF2'
        },
        '& .slick-dots li.slick-active button:before': {
            color: '#D8D9D9'
        },
        '& .slickImages': {
            margin: 'auto'
        },
        '& .sliderItemContent': {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-80%, -50%)',
            zIndex: 99,
            width: '50%',
            color: theme.palette.primary.contrastText
        },
        '& .sliderTitle': {
            marginBottom: 25
        },
        '& .sliderContent': {
        },
        '& .sliderItem': {
            margin: 'auto',
            maxWidth: '90%'
        },
        '& .sliderItem:before': {
            content: "' ' !important",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            // background: 'rgb(58 190 255 / 15%)',
            zIndex: '1'
        }
    }
});
export default AuthStyles;