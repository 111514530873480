import React from 'react';
import { MenuItem, FormControl, Select, Grid, InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

// Import Styles
import LayoutStyles from '../../layouts/style.jsx';
import style from "./style.jsx";
import { ChevDownIcon } from '../../assets/svg/index.js';

function SelectComponent(props) {

  /**
   * Define Props
   */
  const { classes, className, list, value, propertyName, label, noOutline, onSelectChange, variant, displayPropertyName, placeholder, isDisabled } = props;

  /**
   * Handle Change
   * @param {*} event
   */
  const handleChange = (event) => {
    onSelectChange(event.target.value);
  };

  return (
    <Grid className={`selectComponent ${classes.selectComponent} ${noOutline ? classes.outLineNone : null}`}>
      <FormControl fullWidth>
        <InputLabel>
          {label}
        </InputLabel>
        <Select
          placeholder={placeholder}
          value={value}
          label={label}
          variant={variant}
          displayEmpty
          onChange={handleChange}
          className={className}
          disabled={isDisabled}
          IconComponent={() => <ChevDownIcon />}
        >
          {
            list.map((value, index) => {
              const listValue = propertyName ? value[propertyName] : value;
              const displayProperty = displayPropertyName ? value[displayPropertyName] : listValue;
              return (
                <MenuItem value={listValue} key={index}>
                  {displayProperty}
                </MenuItem>
              );
            })
          }
          {
            !list || list.length === 0 &&
            <MenuItem disabled>
              No Data
            </MenuItem>
          }
        </Select>
      </FormControl>
    </Grid>
  );
}


/**
 * Define Component Props
 */
SelectComponent.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  list: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object
  ]),
  propertyName: PropTypes.string,
  noOutline: PropTypes.bool,
  onSelectChange: PropTypes.func,
  variant: PropTypes.string,
  displayPropertyName: PropTypes.string,
  isDisabled: PropTypes.bool
};


/**
 * Set Default Values
 */
SelectComponent.defaultProps = {
  className: "",
  classes: {},
  list: [],
  value: '',
  label: '',
  noOutline: false,
  onSelectChange: () => { },
  isDisabled: false
};

export default withStyles((theme) => ({
  ...LayoutStyles(theme),
  ...style(theme)
}))(SelectComponent);