/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';

//  Import Components
import { TableComponent } from '../../components/index.js';
import DialogComponent from '../../components/dialogBox/index.jsx';

//  Import Styles
import LayoutStyles from '../../layouts/style.jsx';

import OrganizationStyle from './style.jsx';

// Import Reducers
import { navigate } from '../../redux/reducer/navigationReducer.js';
import { deleteOrganizationRequest, deployOrganizationRequest, getOrganizationRequest, updateOrganizationRequest, syncOrganizationRequest } from '../../redux/reducer/organizationReducer.js';
import appConstants from '../../constants/appConstants.js';


function ListOrganization(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Define Constants
     */
    const tableHeaders = [
        {
            key: 'name',
            name: 'Name',
            sorting: true,
            tooltip: false,
            width: '20%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'name',
            clickable: true
        },
        {
            key: 'email',
            name: 'Email',
            sorting: true,
            tooltip: true,
            width: '20%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'email',
            removeTextTransform: true
        },
        {
            key: 'domain',
            name: 'Domain Name',
            sorting: true,
            tooltip: false,
            width: '20%',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'domain'
        },
        {
            key: 'is_cloud',
            name: 'Cloud',
            sorting: true,
            tooltip: false,
            width: '15%',
            component: 'text',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'is_cloud'
        },
        {
            key: 'is_mwaa_instance',
            name: 'MWAA Instance',
            sorting: true,
            tooltip: false,
            width: '15%',
            component: 'text',
            isSearch: true,
            searchComponent: 'text',
            searchKey: 'is_mwaa_instance'
        },
        {
            key: 'is_active',
            name: 'Active',
            sorting: true,
            tooltip: false,
            width: '10%',
            component: 'switch',
            isSearch: true,
            searchComponent: 'select',
            list: ['All', 'Active', 'In Active'],
            searchKey: 'is_active',
            disableKey: "disableInActive"
        },
        {
            key: 'actions',
            name: 'Actions',
            width: '5%',
            align: 'center',
            actions: [
                { type: 'deploy', tooltip: true, tooltipText: 'Deploy', disableKey: "hideDeploy" },
                { type: 'sync', tooltip: true, tooltipText: 'Sync', disableKey: "hideSync" },
                { type: 'delete-loader', tooltip: true, tooltipText: 'Delete' }
            ]
        }
    ];

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { organization } = useSelector((state) => state.organization, shallowEqual);

    /**
     * Define States
     */
    const [searchData, setSearchData] = useState({
        "name": "",
        "email": "",
        "domain": ""
    });
    const [sorting, setSorting] = useState({
        sortBy: "name",
        orderBy: "asc"
    });
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });

    /**
     * Get Organizations List
     */
    useEffect(() => {
        dispatch(getOrganizationRequest());
    }, [dispatch]);


    /**
     * Handle Sorting
     * @param {*} sortBy
     * @param {*} orderBy
     */
    const onClickSorting = (sortBy, orderBy) => {
        setSorting({
            sortBy, orderBy
        });
    };

    /**
     * Handle Component Event
     * @param {*} key
     * @param {*} value
     * @param {*} item
     */
    const onCompnentEvent = (key, value, item) => {
        const request_params = {
            id: item.id,
            [key]: value
        };
        dispatch(updateOrganizationRequest(request_params));
    };

    /**
     * Handle Search
     * @param {*} key
     * @param {*} value
     */
    const onHandleSearchEvent = (key, value) => {
        const search_by = { ...searchData };
        search_by[key] = value;
        setSearchData(search_by);
    };

    /**
     * Measure Add Popup
     */
    const handleMeasureAddEditDialog = () => {
        dispatch(navigate({ path: 'home.add', state: {}, params: [] }));
    };

    /**
     * Table Actions
     */
    const tableOptions = [{ type: "search", customFunction: null }, { type: "columns", customFunction: null }, { type: "add", customFunction: handleMeasureAddEditDialog }];

    /**
     * Handle Cell Click Event
     * @param {*} key
     * @param {*} alert
     */
    const handleCellClickEvent = (key, params) => {
        switch (key) {
            case "name":
                dispatch(navigate({ path: 'home.edit', state: {}, params: [params.id] }));
                break;
            default:
                break;
        }
    };

    /**
     * Prepare Filter Organization
     * @param {*} data
     * @param {*} searchFilters
     * @returns
     */
    const prepareFilterApplications = (data, searchFilters, sortBy, orderBy) => {
        let filterData = [...data];
        const filters = [];
        for (const key of Object.keys(searchFilters)) {
            if (searchFilters[key] !== "") {
                filters.push(key);
            }
        }

        if (filters.length) {
            filterData = data.filter((item) => {
                for (const key of filters) {
                    if (typeof (item[key]) === 'string' && !item[key].toLowerCase().includes(searchFilters[key].toLowerCase())) {
                        return false;
                    }
                    if (key === 'type') {
                        for (const typeItem of item[key]) {
                            if (typeItem.toLowerCase().includes(searchFilters[key].toLowerCase())) {
                                return true;
                            }
                        }
                        return false;
                    }
                }
                return true;
            });
        }
        if (sortBy && orderBy) {
            filterData = _.orderBy(filterData, [sortBy], [orderBy]);
        }
        return filterData;
    };

    /**
     * Handle Delete Event
     */
    const onDelete = (data) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.organizationDeleteMessage,
            data
        });
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: '',
            message: '',
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = (type = "purge") => {
        if (!showDialog?.data?.id) {
            return;
        }
        dispatch(deleteOrganizationRequest({ id: showDialog?.data?.id, type }));
        dialogCancelEventHandle();
    };


    /**
     * Handle Actions Click Event
     * @param {*} key
     * @param {*} alert
     */
    const handleActionsClickEvent = (data, actionName, event) => {
        switch (actionName) {
            case "delete-loader":
                onDelete(data);
                break;
            case "deploy":
                if (data.id) {
                    dispatch(deployOrganizationRequest({ "organization_id": data.id }));
                }
                break;
            case 'sync':
                if (data.id) {
                    dispatch(syncOrganizationRequest({ "organization_id": data.id }));
                }
                break;
            default:
                break;
        }
    };

    /**
     * Filter Organization using UseMemo
     */
    const filterdOrganization = useMemo(() => prepareFilterApplications(organization, searchData, sorting.sortBy, sorting.orderBy), [organization, searchData, sorting.sortBy, sorting.orderBy]);

    return (
        <Grid container className={classes.landinPageContainer}>
            <TableComponent
                title="Organization"
                description="list of organization"
                styleType="striped"
                headers={tableHeaders}
                data={filterdOrganization}
                sortBy={sorting.sortBy}
                orderBy={sorting.orderBy}
                searchData={searchData}
                onClickSorting={onClickSorting}
                onCompnentEvent={onCompnentEvent}
                onHandleSearchEvent={onHandleSearchEvent}
                onCellClick={handleCellClickEvent}
                onClickActions={handleActionsClickEvent}
                options={tableOptions}
                height="calc(100vh - 450px)"
                NoResultText="No Organization Found"
            />
            {
                showDialog.open && (
                    <DialogComponent
                        open={showDialog.open}
                        title={showDialog.title}
                        message={showDialog.message}
                        data={showDialog.data}
                        primaryText={"Deactivate"}
                        purgeText={"Delete"}
                        optionButtonContent={
                            {
                                show: true,
                                title: appConstants.dialogBox.purge,
                                color: 'secondary'
                            }
                        }
                        onCancel={dialogCancelEventHandle}
                        onConfirm={dialogConfirmEventHandle}
                        onOptionDialogButtonClick={dialogConfirmEventHandle}
                    />
                )
            }
        </Grid>
    );
}

// default props
ListOrganization.defaultProps = {
    classes: {}
};

// prop types
ListOrganization.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...OrganizationStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(ListOrganization);