import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { Button, FormControlLabel, FormHelperText, Grid, IconButton, Stack, Switch, Tooltip, Typography, Link } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';

//import Componetns
import { DeleteIcon, PlayIcon2 } from '../../assets/svg/index.js';
import { LoaderButtonComponent, TextBoxComponent, PasswordInputComponent, SelectCheckBoxComponent, DatePickerComponent, SelectComponent, NumberInputComponent } from '../../components/index.js';
import DialogComponent from '../../components/dialogBox/index.jsx';

//import Styles
import GeneralStyle from "./style.jsx";
import LayoutStyles from '../../layouts/style.jsx';

// Import Constants
import appConstants from '../../constants/appConstants.js';

// Import Helpers
import { get_client_domain, setRequiredErrorMessage } from '../../helpers/appHelpers';

// Import Reducers
import { navigate } from '../../redux/reducer/navigationReducer.js';
import {
    getOrganizationByIdRequest, createOrganizationRequest, updateOrganizationRequest, updateOrganizationChanges, clearOrganizationDetail,
    deployOrganizationRequest,
    deleteOrganizationRequest
} from '../../redux/reducer/organizationReducer';


function EditOrganization(props) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();
    const { id: organizationId } = useParams();
    const client_domain = get_client_domain();

    /**
     * Define State Variables
     */
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [showDialog, setShowDialog] = useState({
        open: false,
        title: '',
        message: '',
        data: {}
    });
    const [formSubmit, setFormSubmit] = useState(false);

    /**
     * Redux Select Action
     * @param {*} event
     */
    const { detail, isLoading } = useSelector((state) => state.organization, shallowEqual);
    const connectionTpes = useSelector((state) => state.default.connectionTpes);

    /**
     * Load organization
     * @param {Organization Id to be loaded} organizationId
     */
    const loadOrganization = (organizationId) => {
        dispatch(getOrganizationByIdRequest(organizationId));
        setIsPageLoading(false);
    };

    /**
     * Get Organizations By Id
     */
    useEffect(() => {
        if (organizationId && isPageLoading) {
            loadOrganization(organizationId);
        }
    }, [organizationId, isPageLoading]);

    /**
     * Handle component unmount event
     */
    useEffect(() => {
        return (() => {
            dispatch(clearOrganizationDetail());
        });
    }, [organizationId]);


    /**
     * Handle Input Box Changes
     */
    const handleChange = (key, value, field) => {
        const updateDetail = { ...detail };
        if (field) {
            updateDetail[field] = {
                ...updateDetail[field],
                [key]: value
            };
            if (field === 'airflow' && key === 'type') {
                updateDetail[field] = {
                    ...updateDetail[field],
                    environment_name: ''
                };
            }
        } else {
            updateDetail[key] = value;
        }
        dispatch(updateOrganizationChanges(updateDetail));
    };

    /**
     * Handle Cancel Event
     */
    const handleCancelEvent = () => {
        dispatch(navigate({ path: 'home.root', state: {}, params: [] }));
    };

    /**
     * Handle Form Submit
     */
    const handleFormSubmit = () => {
        setFormSubmit(true);
        console.log("HFS", detail);
        if (detail.connectors.length) {
            if (detail.id && organizationId) {
                dispatch(updateOrganizationRequest(detail));
            } else {
                dispatch(createOrganizationRequest(detail));
            }
        }
    };

    /**
     * Handle Deploy Event
     */
    const handleDeployEvent = () => {
        if (detail.id) {
            dispatch(deployOrganizationRequest({ "organization_id": detail.id }));
        }
    };

    /**
     * Handle Delete Event
     */
    const onDelete = (data) => {
        setShowDialog({
            open: true,
            title: appConstants.dialogBox.delete,
            message: appConstants.dialogBox.organizationDeleteMessage,
            data
        });
    };

    /**
     * Handle Dialog Box Cancel Event
     */
    const dialogCancelEventHandle = () => {
        setShowDialog({
            open: false,
            title: '',
            message: '',
            data: {}
        });
    };

    /**
     * Delete Item After Confirmation
     * @param {*} data
     */
    const dialogConfirmEventHandle = (type = "purge") => {
        if (!detail?.id) {
            return;
        }
        dispatch(deleteOrganizationRequest({ id: detail.id, type }));
        dialogCancelEventHandle();
    };


    return (
        <ValidatorForm className="w-100" noValidate onSubmit={handleFormSubmit}>
            <Grid item xs={12} className={classes.addEditContainer}>
                <Grid container spacing={6}>
                    <Grid item className={classes.sectionContainer} xs={12}>
                        <Grid className={classNames(classes.headerConatiner, classes.actionConatiner)}>
                            <Typography variant="h4" className="">
                                {appConstants.organziation.basicDeatils}
                            </Typography>
                            {
                                detail?.id && detail.is_active &&
                                <Stack direction={"row"}>
                                    <LoaderButtonComponent type="button" isCircleLoader isLoading={detail.is_deploying}>
                                        <Tooltip title={"Deploy"}>
                                            <IconButton onClick={() => handleDeployEvent()}>
                                                <PlayIcon2 />
                                            </IconButton>
                                        </Tooltip>
                                    </LoaderButtonComponent>
                                    <LoaderButtonComponent type="button" isCircleLoader isLoading={detail.is_delete}>
                                        <Tooltip title={"Delete"}>
                                            <IconButton onClick={() => onDelete(detail)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </LoaderButtonComponent>
                                </Stack>
                            }
                        </Grid>
                        <Grid className={classes.bodyContainer}>
                            <Grid container spacing={6} className="pt-2">
                                <Grid item xs={4}>
                                    <TextBoxComponent
                                        name="name"
                                        fullWidth
                                        label={appConstants.organziation.name}
                                        variant="standard"
                                        size="medium"
                                        value={detail?.name ?? ''}
                                        validators={['required']}
                                        errorMessages={[appConstants.errorMessages.nameRequired]}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextBoxComponent
                                        id="form_txt_email"
                                        name="email"
                                        fullWidth
                                        label={appConstants.organziation.email}
                                        variant="standard"
                                        size="medium"
                                        value={detail?.email ?? ''}
                                        validators={['required', 'isEmail']}
                                        errorMessages={
                                            [
                                                appConstants.errorMessages.emailRequired,
                                                appConstants.errorMessages.invalidMail
                                            ]
                                        }
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <PasswordInputComponent
                                        id="form_txt_password"
                                        name={'password'}
                                        value={detail?.password ?? ''}
                                        fullWidth
                                        label={appConstants.labels.authentication.password}
                                        variant="standard"
                                        validators={['required']}
                                        errorMessages={[setRequiredErrorMessage(appConstants.labels.authentication.password)]}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={(event, value) => handleChange(event.target.name, value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextBoxComponent
                                        name="phone_number"
                                        fullWidth
                                        label={appConstants.organziation.phone}
                                        variant="standard"
                                        size="medium"
                                        value={detail?.phone_number ?? ''}
                                        validators={['required']}
                                        errorMessages={[appConstants.errorMessages.phoneRequired]}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.sectionContainer} xs={12}>
                        <Grid className={classes.headerConatiner}>
                            <Typography variant="h4" className="">
                                {appConstants.organziation.domain}
                            </Typography>
                        </Grid>
                        <Grid className={classes.bodyContainer}>
                            <Grid container className="pt-1">
                                <Grid item xs={6}>
                                    <TextBoxComponent
                                        name="domain"
                                        fullWidth
                                        label={appConstants.organziation.domainName}
                                        variant="standard"
                                        size="medium"
                                        value={detail?.domain ?? ''}
                                        validators={['required']}
                                        errorMessages={[appConstants.errorMessages.domainName]}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        disabled={(detail?.deployed_status?.toLowerCase() === "success")}
                                    />
                                </Grid>
                                <Grid>
                                    {
                                        detail?.deployed_status?.toLowerCase() === "success" ?
                                            <Link href={`https://${detail?.domain ?? ''}.${client_domain}`} underline="none" target="_blank" >
                                                {`${detail?.domain ?? ''}.${client_domain}`}
                                            </Link>
                                            :
                                            <Typography variant="body1" className={"pt-1 pl-1 bold"}>
                                                {`${detail?.domain ?? ''}.${client_domain}`}
                                            </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.sectionContainer} xs={12}>
                        <Grid className={classes.headerConatiner}>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant="h4" className="">
                                    {appConstants.organziation.database}
                                </Typography>
                                <FormControlLabel
                                    control={<Switch size="small" color="secondary" checked={detail?.database?.is_cloud ?? true} onChange={(event) => handleChange('is_cloud', event.target.checked, 'database')} name="is_cloud" />}
                                    label={appConstants.organziation.cloud}
                                />
                            </Grid>
                        </Grid>
                        <Grid className={classes.bodyContainer}>
                            <Grid container spacing={6} className="pt-2">
                                <Grid item xs={6}>
                                    <TextBoxComponent
                                        name="database"
                                        fullWidth
                                        label={appConstants.organziation.databasename}
                                        variant="standard"
                                        size="medium"
                                        value={detail?.database?.name ?? ''}
                                        validators={['required']}
                                        errorMessages={[appConstants.errorMessages.databaseRequired]}
                                        onChange={(event) => handleChange('name', event.target.value, 'database')}
                                        disabled={(detail?.deployed_status?.toLowerCase() === "success")}
                                    />
                                </Grid>
                                {
                                    !detail?.database?.is_cloud &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <TextBoxComponent
                                                name="host"
                                                fullWidth
                                                label={appConstants.organziation.host}
                                                variant="standard"
                                                size="medium"
                                                value={detail?.database?.host ?? ''}
                                                validators={['required']}
                                                errorMessages={[appConstants.errorMessages.host]}
                                                onChange={(event) => handleChange(event.target.name, event.target.value, 'database')}
                                                disabled={(detail?.deployed_status?.toLowerCase() === "success")}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextBoxComponent
                                                name="port"
                                                fullWidth
                                                label={appConstants.organziation.port}
                                                variant="standard"
                                                size="medium"
                                                value={detail?.database?.port ?? ''}
                                                validators={['required']}
                                                errorMessages={[appConstants.errorMessages.port]}
                                                onChange={(event) => handleChange(event.target.name, event.target.value, 'database')}
                                                disabled={(detail?.deployed_status?.toLowerCase() === "success")}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextBoxComponent
                                                name="user_name"
                                                fullWidth
                                                label={appConstants.organziation.userName}
                                                variant="standard"
                                                size="medium"
                                                value={detail?.database?.user_name ?? ''}
                                                validators={['required']}
                                                errorMessages={[appConstants.errorMessages.userName]}
                                                onChange={(event) => handleChange(event.target.name, event.target.value, 'database')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <PasswordInputComponent
                                                name="password"
                                                fullWidth
                                                label={appConstants.organziation.password}
                                                variant="standard"
                                                size="medium"
                                                value={detail?.database?.password ?? ''}
                                                validators={['required']}
                                                errorMessages={[appConstants.errorMessages.password]}
                                                onChange={(event) => handleChange(event.target.name, event.target.value, 'database')}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.sectionContainer} xs={12}>
                        <Grid className={classes.headerConatiner}>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant="h4" className="">
                                    {appConstants.organziation.airflow}
                                </Typography>
                                <SelectComponent
                                    className={"instanceType"}
                                    list={appConstants.organziation.airflowTypes}
                                    value={detail?.airflow?.type ?? appConstants.organziation.airflowTypes[0]}
                                    placeholder={appConstants.organziation.instanceSize}
                                    noOutline
                                    variant="standard"
                                    onSelectChange={(value) => handleChange('type', value, 'airflow')}
                                />
                            </Grid>
                        </Grid>
                        <Grid className={classes.bodyContainer} style={{ display: `${detail?.airflow?.type !== appConstants.organziation.airflowTypes[0] ? 'block' : 'none'}` }}>
                            <Grid container spacing={6} className="pt-2">
                            {
                                    detail?.airflow?.type !== appConstants.organziation.airflowTypes[0] &&
                                    <Grid item xs={6}>
                                        <TextBoxComponent
                                            name="environment_name"
                                            fullWidth
                                            label={appConstants.organziation.EnvironmentName}
                                            variant="standard"
                                            size="medium"
                                            value={detail?.airflow?.environment_name ?? ''}
                                            validators={['required']}
                                            errorMessages={[appConstants.errorMessages.EnvironmentName]}
                                            onChange={(event) => handleChange(event.target.name, event.target.value, 'airflow')}
                                        />
                                    </Grid>
                                }
                                {
                                    detail?.airflow?.type === appConstants.organziation.airflowTypes[1] &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <TextBoxComponent
                                                name="region_name"
                                                fullWidth
                                                label={appConstants.organziation.regionName}
                                                variant="standard"
                                                size="medium"
                                                value={detail?.airflow?.region_name ?? ''}
                                                validators={['required']}
                                                errorMessages={[appConstants.errorMessages.regionName]}
                                                onChange={(event) => handleChange(event.target.name, event.target.value, 'airflow')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextBoxComponent
                                                name="access_key"
                                                fullWidth
                                                label={appConstants.organziation.accessKey}
                                                variant="standard"
                                                size="medium"
                                                value={detail?.airflow?.access_key ?? ''}
                                                validators={['required']}
                                                errorMessages={[appConstants.errorMessages.accessKey]}
                                                onChange={(event) => handleChange(event.target.name, event.target.value, 'airflow')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <PasswordInputComponent
                                                name="secret_key"
                                                fullWidth
                                                label={appConstants.organziation.secretKey}
                                                variant="standard"
                                                size="medium"
                                                value={detail?.airflow?.secret_key ?? ''}
                                                validators={['required']}
                                                errorMessages={[appConstants.errorMessages.secretKey]}
                                                onChange={(event) => handleChange(event.target.name, event.target.value, 'airflow')}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }

                                {
                                    detail?.airflow?.type === appConstants.organziation.airflowTypes[2] &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <TextBoxComponent
                                                name="airflow_url"
                                                fullWidth
                                                label={appConstants.organziation.airflowURL}
                                                variant="standard"
                                                size="medium"
                                                value={detail?.airflow?.airflow_url ?? ''}
                                                validators={['required']}
                                                errorMessages={[appConstants.errorMessages.airflowUrl]}
                                                onChange={(event) => handleChange(event.target.name, event.target.value, 'airflow')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextBoxComponent
                                                name="user_name"
                                                fullWidth
                                                label={appConstants.organziation.userName}
                                                variant="standard"
                                                size="medium"
                                                value={detail?.airflow?.user_name ?? ''}
                                                validators={['required']}
                                                errorMessages={[appConstants.errorMessages.userName]}
                                                onChange={(event) => handleChange(event.target.name, event.target.value, 'airflow')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <PasswordInputComponent
                                                name="password"
                                                fullWidth
                                                label={appConstants.organziation.password}
                                                variant="standard"
                                                size="medium"
                                                value={detail?.airflow?.password ?? ''}
                                                validators={['required']}
                                                errorMessages={[appConstants.errorMessages.password]}
                                                onChange={(event) => handleChange(event.target.name, event.target.value, 'airflow')}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                                {
                                    detail?.airflow?.type !== appConstants.organziation.airflowTypes[0] &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <SelectComponent
                                                list={["S", "M", "L", "XL", "2XL"]}
                                                value={detail?.airflow?.instance_size ?? ''}
                                                label={appConstants.organziation.instanceSize}
                                                noOutline
                                                variant="standard"
                                                onSelectChange={(value) => handleChange('instance_size', value, 'airflow')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <NumberInputComponent
                                                name="cluster_size"
                                                fullWidth
                                                label={appConstants.organziation.clusterSize}
                                                variant="standard"
                                                size="medium"
                                                value={detail?.airflow?.cluster_size ?? ''}
                                                errorMessages={[appConstants.errorMessages.clusterSize, "Cluster Size Minimum is 1", "Cluster Size Maximum is 10"]}
                                                validators={['required', 'minNumber:1', 'maxNumber:10']}
                                                minValue={1}
                                                maxValue={10}
                                                onChange={(value) => handleChange('cluster_size', value, 'airflow')}
                                                retrictZero
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.sectionContainer} xs={12}>
                        <Grid className={classes.headerConatiner}>
                            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant="h4" className="">
                                    {appConstants.organziation.configuration}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid className={classes.bodyContainer}>
                            <Grid container spacing={6} className="pt-2">
                                <Grid item xs={4}>
                                    <SelectCheckBoxComponent
                                        list={connectionTpes || []}
                                        label={appConstants.organziation.connectors}
                                        value={detail.connectors || []}
                                        variant="standard"
                                        displayPropertyName={"name"}
                                        propertyName={"id"}
                                        showSelectAll
                                        onSelectChange={(value) => handleChange("connectors", value)}
                                    />
                                    {
                                        formSubmit && !detail.connectors.length &&
                                        <FormHelperText error>
                                            Atleast Select One Connector
                                        </FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    <DatePickerComponent
                                        value={detail?.license_start_date ?? new Date()}
                                        onChange={(value) => handleChange("license_start_date", value)}
                                        maxDate={detail?.license_end_date}
                                        label="License Start Date"
                                        format={"YYYY-MM-DD"}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <DatePickerComponent
                                        value={detail?.license_end_date ?? null}
                                        onChange={(value) => handleChange("license_end_date", value !== "Invalid date" ? value : null)}
                                        minDate={detail?.license_start_date ?? new Date()}
                                        label="License End Date"
                                        format={"YYYY-MM-DD"} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.actions}>
                <Grid item xs={12} align="right">
                    <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        size="small"
                        className="mr-1"
                        onClick={handleCancelEvent}
                    >
                        {appConstants.organziation.cancel}
                    </Button>
                    <LoaderButtonComponent
                        size={'small'}
                        isLoading={isLoading}
                    >
                        {appConstants.organziation.save}
                    </LoaderButtonComponent>
                </Grid>
                {
                    showDialog.open && (
                        <DialogComponent
                            open={showDialog.open}
                            title={showDialog.title}
                            message={showDialog.message}
                            data={showDialog.data}
                            primaryText={"Deactivate"}
                            purgeText={"Delete"}
                            optionButtonContent={
                                {
                                    show: true,
                                    title: appConstants.dialogBox.purge,
                                    color: 'secondary'
                                }
                            }
                            onCancel={dialogCancelEventHandle}
                            onConfirm={dialogConfirmEventHandle}
                            onOptionDialogButtonClick={dialogConfirmEventHandle}
                        />
                    )
                }
            </Grid>
        </ValidatorForm>
    );
}

// default props
EditOrganization.defaultProps = {
    classes: {}
};

// prop types
EditOrganization.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...GeneralStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(EditOrganization);