import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthRoute, PrivateRoute } from "../authRoutes";

import AppNavigator from "../components/appNavigator/index.jsx";
import Login from "../containers/authentication/login.jsx";
import ForgotPassword from "../containers/authentication/forgotpassword.jsx";
import ResetPassword from "../containers/authentication/resetPassword.jsx";
import Organization from "../containers/organization/index.jsx";
import ListOrganization from "../containers/organization/listOrganization.jsx";
import EditOrganization from "../containers/organization/editOrganization.jsx";

const routes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<AuthRoute component={Login} />} />
        <Route exact path="login" element={<AuthRoute component={Login} />} />
        <Route exact path="forgotpassword" element={<AuthRoute component={ForgotPassword} />} />
        <Route exact path="resetpassword/:hash" element={<AuthRoute component={ResetPassword} />} />
        <Route path="organization" element={<PrivateRoute component={Organization} />}>
          <Route index element={<ListOrganization />} />
          <Route exact path="add" element={<EditOrganization />} />
          <Route exact path="edit/:id" element={<EditOrganization />} />
        </Route>
      </Routes>
      <AppNavigator />
    </BrowserRouter>
  );
};

export default routes;