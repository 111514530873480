import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { TableRow, TableCell, IconButton, Tooltip, Table, Grid } from "@mui/material";

//Import Components
import { ValueEditorComponent, ChipComponent } from "../index";

// Validation
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Images
import { CheckIcon, CloseIcon2, ColorDropIcon } from "../../assets/svg";
import palette from "../../assets/theme/palette";

const AddTableBodyRowComponent = (props) => {

    /**
     * Define Props
     */
    const { classes, headers, haveSubTable, onClickActions, completeNewRowData, parentDetail, selectComponentList } = props;

    /**
     * Define State
     */
    const [newRowData, setNewRowData] = useState();

    /**
     * Define Use Effects
     */
    useEffect(() => {
        /**
         * Setting initial Data
         */
        const initialData = {};
        for (const field of headers.filter((header) => header.key !== 'actions')) {
            if (field.datatype === 'array') {
                initialData[field.key] = [];
            } else if (field.key === 'color') {
                initialData[field.key] = palette.secondary.main;
            } else if (field.datatype === 'text') {
                initialData[field.key] = '';
            }
        }
        setNewRowData(initialData);
    }, [headers]);

    /**
     * Handle Component Events
     * @param {*} key
     * @param {*} value
     */
    const onHandleComponentEvent = (key, event) => {
        const value = key === 'associated_tags' ? event : event.target.value;
        setNewRowData((newRowData) => { return { ...newRowData, [key]: value }; });
    };

    /**
     * Handle Adding new Chip in the Chip Component
     * @param {*} value
     * @param {*} key
     */
    const handleChipAdd = (value, key) => {
        setNewRowData((newRowData) => { return { ...newRowData, [key]: [...newRowData[key], value] }; });
    };

    /**
     * Handle Deleting Chip in the Chip Component
     * @param {*} value
     * @param {*} key
     */
    const handleChipDelete = (value, key) => {
        const updatedChips = newRowData[key].filter((chip) => chip !== value);
        setNewRowData((newRowData) => { return { ...newRowData, [key]: updatedChips }; });
    };

    /**
     * Bind Cell Component
     * @param {*} item
     * @returns
     */
    const bindCellComponent = (header) => {
        switch (header.component) {
            case 'textbox':
                return (
                    <ValueEditorComponent
                        noOutline
                        variant="standard"
                        label={header.componentLabel}
                        value={newRowData?.[header.key]}
                        type={header.datatype || newRowData[header.componentKey] || "integer"}
                        onChange={(event) => onHandleComponentEvent(header.key, event)}
                        name={header.name}
                        isRequired={header.isRequired}
                        placeholder={header.placeholder}
                    />
                );
            case 'chips':
                return (
                    <ChipComponent
                        chipClass={`${header.className}`}
                        data={newRowData?.[header.key] || []}
                        labelKey={header.valueKey || ""}
                        limit={header.limit || 1}
                        editable={!header.isNotEditable}
                        onChipAdd={(value) => handleChipAdd(value, header.key)}
                        onChipRemove={(selectedChip) => handleChipDelete(selectedChip, header.key)}
                        add={header.isAdd}
                        addType={header.chipAddType || "text"}
                        availableList={selectComponentList[header.key] || []}
                        handleChipEvent={(event) => onHandleComponentEvent(header.key, event)}
                    />
                );
            case 'subtagchips':
                const subChips = [...selectComponentList[header.key]];
                const index = subChips.findIndex((data) => data.id === parentDetail);
                if (index >= 0) {
                    subChips.splice(index, 1);
                }
                return (
                    <ChipComponent
                        chipClass={`${header.className}`}
                        data={newRowData?.[header.key] || []}
                        labelKey={header.valueKey || ""}
                        limit={header.limit || 1}
                        editable={!header.isNotEditable}
                        onChipAdd={(value) => handleChipAdd(value, header.key)}
                        onChipRemove={(selectedChip) => handleChipDelete(selectedChip, header.key)}
                        add={header.isAdd}
                        addType={header.chipAddType || "text"}
                        availableList={subChips || []}
                        handleChipEvent={(event) => onHandleComponentEvent(header.key, event)}
                    />
                );
            case 'color':
                return (
                    <IconButton disableRipple className={classes.colorPicker}>
                        <ColorDropIcon dropColor={newRowData?.[header.key] ? newRowData[header.key] : "red"} />
                        <input className="typeColor" type="color" onChange={(event) => onHandleComponentEvent(header.key, event)} />
                    </IconButton>
                );
            default:
                return (
                    <span />
                );
        }
    };

    /**
     * Handle creating or cancelling application
     */
    const handleActionsClick = (data, actionName, event) => {
        if (actionName === 'create' || actionName === 'save') {
            onClickActions(data, actionName, event, parentDetail);
        }

        completeNewRowData();
    };

    /**
     * Bind Cell Actions
     */
    const bindCellActions = (actions) => {
        return (
            actions && actions.map((item, index) => {
                let action = null;
                switch (item) {
                    case 'create':
                    case 'save':
                        action = (<CheckIcon key={`${item}-${index}`} />);
                        break;
                    case 'cancel':
                        action = (<CloseIcon2 key={`${item}-${index}`} />);
                        break;
                    default:
                        action = null;
                        break;
                }
                return action ? (
                    <Tooltip key={`tooltip-${index}`} title={item} arrow>
                        <IconButton
                            key={`icon-${index}`}
                            aria-label="Cancel"
                            color="inherit"
                            className={'p5'}
                            onClick={(event) => handleActionsClick(newRowData, item, event)}
                        >
                            {action}
                        </IconButton>
                    </Tooltip>
                ) : null;
            })
        );
    };

    /**
     * Render Cell
     * @param {*} item
     * @param {*} index
     * @returns
     */
    const renderCell = (header) => {
        if (header.key === 'actions') {
            return bindCellActions(header.actions);
        }
        return bindCellComponent(header);
    };

    return (
        <TableRow>
            <TableCell colSpan={haveSubTable ? headers.length + 1 : headers.length} sx={{ padding: '0 !important' }}>
                <ValidatorForm onSubmit={(event) => handleActionsClick(newRowData, 'create', event)}>
                    <Table>
                        <TableRow>
                            {
                                headers && headers.map((header, index) => (
                                    <TableCell key={index} style={{ width: header.width || '', textAlign: header.align || 'left', padding: 10 }}>
                                        <Grid className={`dflex alignCenter ${header.align && "justifyCenter"}`}>
                                            {renderCell(header, index)}
                                        </Grid>
                                    </TableCell>
                                ))
                            }
                            {
                                haveSubTable ?
                                    <TableCell sx={{ padding: '0px 24px !important' }} /> :
                                    null
                            }
                        </TableRow>
                    </Table>
                </ValidatorForm>
            </TableCell>
        </TableRow>
    );
};

/**
 * Set Component Props
 */
AddTableBodyRowComponent.propTypes = {
    classes: PropTypes.object,
    headers: PropTypes.array,
    haveSubTable: PropTypes.bool,
    onClickActions: PropTypes.func,
    completeNewRowData: PropTypes.func,
    parentDetail: PropTypes.string,
    selectComponentList: PropTypes.object
};

/**
 * Set Default Values
 */
AddTableBodyRowComponent.defaultProps = {
    classes: [],
    headers: [],
    haveSubTable: false,
    completeNewRowData: () => { },
    onClickActions: () => { },
    selectComponentList: {}
};

export default AddTableBodyRowComponent;