const style = (theme) => ({
    headerOption: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiInput-root': {
            marginTop: '0px !important'
        },
        '& .MuiSelect-select, div#demo-simple-select': {
            paddingRight: '10px !important'
        },
        '& .SearchIcon': {
            width: 22,
            height: 22,
            '&:hover path#Path_1197': {
                fill: theme.palette.secondary.main
            }
        },
        '& .DownloadIcon': {
            width: '24px',
            height: '24px'
        },
        '& .DownloadIcon2': {
            '&:hover path': {
                fill: theme.palette.secondary.main
            }
        },
        '& .ThreeColumnIcon, .PlusIcon': {
            '&:hover': {
                fill: theme.palette.secondary.main
            }
        }
    },
    columnList: {
        minWidth: 200,
        '& .MuiCheckbox-root': {
            padding: '0px !important'
        },
        '& .MuiListItemIcon-root': {
            minWidth: '34px'
        },
        '& .MuiListItem-root': {
            padding: '4px 26px 4px 22px !important'
        }
    },
    columnPopover: {
        maxHeight: '400px',
        minHeight: '400px',
        overflowY: 'auto'
    },
    poperComponent: {
        '& .MuiPopover-paper': {
            paddingBottom: 10,
            paddingTop: 10
        }
    },
    searchBoxColumn: {
        '&.MuiListItem-root': {
            paddingBottom: '10px !important'
        },
        '& .searchBox': {
            padding: '1px 10px !important'
        }
    },
    gridList: {
        maxHeight: '335px',
        overflowY: 'auto'
    }
});
export default style;