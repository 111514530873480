import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
  getOrganizationRequest, getOrganizationSuccess, getOrganizationFailure, createOrganizationRequest, createOrganizationuccess,
  createOrganizationFailure, updateOrganizationRequest, updateOrganizationuccess, updateOrganizationFailure, deleteOrganizationRequest,
  deleteOrganizationuccess, deleteOrganizationFailure, getOrganizationByIdRequest, getOrganizationByIdSuccess, getOrganizationByIdFailure, deployOrganizationRequest, deployOrganizationSuccess, deployOrganizationFailure,
  syncOrganizationRequest, syncOrganizationSuccess, syncOrganizationFailure
} from '../reducer/organizationReducer';
import { displyAlert } from "../reducer/alertReducer";
import { navigate } from '../reducer/navigationReducer';

// Import Services
import { organizationService } from '../service';

// Import Constants
import appConstants from "../../constants/appConstants";


/**
 * Get Organization
 * @returns
 */
const getOrganization = (action$) => action$.pipe(
  ofType(getOrganizationRequest),
  mergeMap(() => {
    return from(organizationService.getOrganization()).pipe(
      concatMap((res) => { return of(getOrganizationSuccess(res.data)); }),
      catchError((error) => { return of(getOrganizationFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })); })
    );
  })
);

/**
 * Get Organization By ID
 * @returns
 */
const getOrganizationById = (action$) => action$.pipe(
  ofType(getOrganizationByIdRequest),
  mergeMap(({ payload }) => {
    return from(organizationService.getOrganizationById(payload)).pipe(
      concatMap((res) => { return of(getOrganizationByIdSuccess(res.data)); }),
      catchError((error) => { return of(getOrganizationByIdFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })); })
    );
  })
);


/**
 * Create Organization
 * @param {*} action$
 * @returns
 */
const createOrganization = (action$) => action$.pipe(
  ofType(createOrganizationRequest),
  mergeMap(({ payload }) => {
    return from(organizationService.createOrganization(payload)).pipe(
      concatMap((res) => { return of(createOrganizationuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.created }), navigate({ path: 'home.root', state: {} })); }),
      catchError((error) => { return of(createOrganizationFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })); })
    );
  })
);

/**
 * Update Organization
 * @param {*} action$
 * @returns
 */
const updateOrganization = (action$) => action$.pipe(
  ofType(updateOrganizationRequest),
  mergeMap(({ payload: { id, ...rest } }) => {
    return from(organizationService.updateOrganization(id, rest)).pipe(
      concatMap((res) => { return of(updateOrganizationuccess(res.data), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }), navigate({ path: 'home.root', state: {} })); }),
      catchError((error) => of(updateOrganizationFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
    );
  })
);

/**
 * Delete Organization
 * @param {*} action$
 * @returns
 */
const deleteOrganization = (action$) => action$.pipe(
  ofType(deleteOrganizationRequest),
  mergeMap(({ payload: { id, type } }) => {
    return from(organizationService.deleteOrganization(id, type)).pipe(
      concatMap(() => of(deleteOrganizationuccess({ id, type }), displyAlert({ 'type': 'success', 'message': type === "purge" ? appConstants.successMessages.deleted : appConstants.successMessages.deactivated }), navigate({ path: 'home.root', state: {} }))),
      catchError((error) => of(deleteOrganizationFailure(id), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
    );
  })
);

/**
 * Deploy Organization
 * @param {*} action$
 * @returns
 */
const deployOrganization = (action$) => action$.pipe(
  ofType(deployOrganizationRequest),
  mergeMap(({ payload }) => {
    return from(organizationService.deployOrganization(payload)).pipe(
      concatMap(() => { return of(deployOrganizationSuccess(payload), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.deploy })); }),
      catchError((error) => { return of(deployOrganizationFailure(payload), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })); })
    );
  })
);

/**
 * Sync Organization
 * @param {*} action$
 * @returns
 */
const syncOrganization = (action$) => action$.pipe(
  ofType(syncOrganizationRequest),
  mergeMap(({ payload }) => {
    return from(organizationService.syncOrganization(payload)).pipe(
      concatMap(() => { return of(syncOrganizationSuccess(payload), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.sync })); }),
      catchError((error) => { return of(syncOrganizationFailure(payload), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })); })
    );
  })
);


// Export All Organization Functions
export const organizationEpic = [
  getOrganization, getOrganizationById, createOrganization, deleteOrganization, updateOrganization, deployOrganization,
  syncOrganization
];