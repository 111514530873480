import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Snackbar, SnackbarContent, Typography, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';

// Import styles
import style from './style.jsx';
import { clearAlert } from '../../redux/reducer/alertReducer.js';

const AlertComponent = (props) => {

	/**
	 * Define Props
	 */
	const { autoHideDuration, classes } = props;
	const dispatch = useDispatch();

	/**
	 * Set State
	 */
	const [open, setOpen] = useState(false);

	/**
	 * Resux Select Action
	 * @param {*} event
	 */
	const { type, message, delay } = useSelector((state) => state.alert);

	/**
	 * Use Effect
	 */
	useEffect(() => {
		if (type) { setOpen(true); }
	}, [type, message]);

	/**
	 * Clear Alert
	 */
	const clear = () => {
		dispatch(clearAlert());
		setOpen(false);
	};

	return (
		<Snackbar
			severity={type}
			open={open}
			anchorOrigin={
				{
					vertical: 'bottom',
					horizontal: 'left'
				}
			}
			autoHideDuration={delay || autoHideDuration}
			onClose={() => clear()}>
			<SnackbarContent
				className={classes[type]}
				message={
					<Typography className={classes.message}>
						{message}
					</Typography>
				}
				action={
[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						className={classes.close}
						onClick={() => clear()}
					>
						<Close className={classes.icon} />
					</IconButton>
				]
}
			/>
		</Snackbar>
	);
};

AlertComponent.propTypes = {
	classes: PropTypes.object,
	autoHideDuration: PropTypes.number
};

AlertComponent.defaultProps = {
	classes: {},
	autoHideDuration: 2000
};


export default withStyles(style)(AlertComponent);