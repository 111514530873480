import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@mui/styles';
import { TextValidator } from 'react-material-ui-form-validator';
import { Grid } from '@mui/material';

// Import Styles
import style from "./style.jsx";

const IntegerInputComponent = (props) => {

	/**
	 * Define Props
	 */
	const { noOutline, classes, onChange, ...rest } = props;

	/**
	 * Define on change event
	 * @param {*} event
	 */
	const handleChangeEvent = (event) => {
		const regex = new RegExp('^[0-9]*$');
		if (event.target.value === '' || regex.test(event.target.value)) {
			onChange(event);
		}
	};

	return (
		<Grid className={noOutline ? classes.outLineNone : null}>
			<TextValidator
				className={noOutline ? classes.outLineNone : null}
				autoComplete={"off"}
				onChange={(event) => handleChangeEvent(event)}
				{...rest}
			/>
		</Grid>
	);
};

/**
 * Define Props
 */
IntegerInputComponent.propTypes = {
	onChange: PropTypes.func,
	classes: PropTypes.object,
	noOutline: PropTypes.bool
};

/**
 * Set Default Values
 */
 IntegerInputComponent.defaultProps = {
	onChange: () => { },
	classes: {},
	noOutline: false
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
	return _.isEqual(prevProps.value, nextProps.value) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
	(theme) => ({
		...style(theme)
	}),
	{ withTheme: true }
)(React.memo(IntegerInputComponent, areEqual));