import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import appConstants from '../../constants/appConstants';

const initialState = {
    isLoading: false,
    organization: [],
    detail: {
        id: "",
        name: "",
        email: "",
        phone_number: "",
        domain: "",
        database: {
            is_cloud: true
        },
        airflow: {
            environment_name: "",
            type: appConstants.organziation.airflowTypes[0]
        },
        is_deploying: false,
        connectors: ["All"],
        license_start_date: moment(new Date()).format("YYYY-MM-DD")
    }
};

const Organizationlice = createSlice({
    name: 'organization',
    initialState: { ...initialState },
    reducers: {
        getOrganizationRequest(state) {
            state.isLoading = true;
        },
        getOrganizationSuccess(state, action) {
            state.organization = action.payload || [];
            state.organization = state.organization.map((organization) => {
                return {
                    ...organization,
                    hideSync: !(organization.deployed_status === "success" && organization.is_active),
                    hideDeploy: !(organization.deployed_status === "pending"),
                    disableInActive: organization.license_end_date ? new Date(organization.license_end_date) < new Date(moment(new Date()).format("YYYY-MM-DD")) : false
                };
            });
            state.isLoading = false;
        },
        getOrganizationFailure(state) {
            state.isLoading = false;
        },
        getOrganizationByIdRequest(state) {
            state.isLoading = true;
        },
        getOrganizationByIdSuccess(state, action) {
            state.detail = action.payload || {};
            state.detail.is_deploying = false;
            state.isLoading = false;
        },
        getOrganizationByIdFailure(state) {
            state.isLoading = false;
        },
        createOrganizationRequest(state) {
            state.isLoading = true;
        },
        createOrganizationuccess(state, action) {
            const { data } = action.payload;
            state.organization = [...state.organization, data];
            state.isLoading = false;
        },
        createOrganizationFailure(state) {
            state.isLoading = false;
        },
        updateOrganizationRequest(state) {
            state.isLoading = true;
        },
        updateOrganizationuccess(state, action) {
            const data = action.payload;
            const index = state.organization.findIndex((organization) => organization.id === data.id);
            state.organization[index] = {
                ...data,
                hideSync: !(data.deployed_status === "success" && data.is_active),
                hideDeploy: !(data.deployed_status === "pending"),
                disableInActive: data.license_end_date ? new Date(data.license_end_date) <= new Date(moment(new Date()).format("YYYY-MM-DD")) : false
            };
            state.isLoading = false;
        },
        updateOrganizationFailure(state) {
            state.isLoading = false;
        },
        deleteOrganizationRequest(state, action) {
            state.detail.is_delete = true;
            if (action.payload) {
                const index = state.organization.findIndex((item) => item.id === action.payload.id);
                if (index > -1) {
                    const organization = state.organization[index];
                    organization.is_delete = true;
                    state.organization.splice(index, 1, { ...organization });
                }
            }
        },
        deleteOrganizationuccess(state, action) {
            state.detail = {
                id: "",
                name: "",
                email: "",
                phone_number: "",
                domain: "",
                database: {
                    is_cloud: true
                },
                airflow: {
                    environment_name: "",
                    type: appConstants.organziation.airflowTypes[0]
                },
                is_deploying: false,
                connectors: ["All"],
                license_start_date: moment(new Date()).format("YYYY-MM-DD")
            };

            if (action.payload.type === "purge") {
                state.organization = state.organization.filter((organization) => organization.id !== action.payload.id);
            } else {
                const index = state.organization.findIndex((organization) => organization.id === action.payload.id);
                if (index > -1) {
                    const organization = state.organization[index];
                    organization.is_active = false;
                    organization.is_delete = false;
                    state.organization.splice(index, 1, { ...organization });
                }
            }
        },
        deleteOrganizationFailure(state, action) {
            state.detail.is_delete = false;
            if (action.payload) {
                const index = state.organization.findIndex((item) => item.id === action.payload);
                if (index > -1) {
                    const organization = state.organization[index];
                    organization.is_delete = false;
                    state.organization.splice(index, 1, { ...organization });
                }
            }
        },
        updateOrganizationChanges(state, action) {
            state.detail = {
                ...state.detail,
                ...action.payload,
                is_deploying: false
            };
        },
        clearOrganizationDetail(state) {
            state.detail = {
                id: "",
                name: "",
                email: "",
                phone_number: "",
                domain: "",
                database: {
                    is_cloud: true
                },
                airflow: {
                    environment_name: "",
                    type: appConstants.organziation.airflowTypes[0]
                },
                is_deploying: false,
                connectors: ["All"],
                license_start_date: moment(new Date()).format("YYYY-MM-DD")
            };
        },
        deployOrganizationRequest(state, action) {
            const { organization_id } = action.payload;
            state.detail.is_deploying = true;
            if (organization_id) {
                const index = state.organization.findIndex((item) => item.id === organization_id);
                if (index > -1) {
                    const organization = state.organization[index];
                    organization.is_deploying = true;
                    state.organization.splice(index, 1, { ...organization });
                }
            }
        },
        deployOrganizationSuccess(state, action) {
            const { organization_id } = action.payload;
            state.detail.is_deploying = false;
            if (organization_id) {
                const index = state.organization.findIndex((item) => item.id === organization_id);
                if (index > -1) {
                    const organization = state.organization[index];
                    organization.is_deploying = false;
                    state.organization.splice(index, 1, { ...organization });
                }
            }
        },
        deployOrganizationFailure(state, action) {
            const { organization_id } = action.payload;
            state.detail.is_deploying = false;
            if (organization_id) {
                const index = state.organization.findIndex((item) => item.id === organization_id);
                if (index > -1) {
                    const organization = state.organization[index];
                    organization.is_deploying = false;
                    state.organization.splice(index, 1, { ...organization });
                }
            }
        },
        syncOrganizationRequest(state, action) {
            const { organization_id } = action.payload;
            state.detail.is_sync = true;
            if (organization_id) {
                const index = state.organization.findIndex((item) => item.id === organization_id);
                if (index > -1) {
                    const organization = state.organization[index];
                    organization.is_sync = true;
                    state.organization.splice(index, 1, { ...organization });
                }
            }
        },
        syncOrganizationSuccess(state, action) {
            const { organization_id } = action.payload;
            state.detail.is_sync = false;
            if (organization_id) {
                const index = state.organization.findIndex((item) => item.id === organization_id);
                if (index > -1) {
                    const organization = state.organization[index];
                    organization.is_sync = false;
                    state.organization.splice(index, 1, { ...organization });
                }
            }
        },
        syncOrganizationFailure(state, action) {
            const { organization_id } = action.payload;
            state.detail.is_sync = false;
            if (organization_id) {
                const index = state.organization.findIndex((item) => item.id === organization_id);
                if (index > -1) {
                    const organization = state.organization[index];
                    organization.is_sync = false;
                    state.organization.splice(index, 1, { ...organization });
                }
            }
        }
    }
});

export const { getOrganizationRequest, getOrganizationSuccess, getOrganizationFailure, createOrganizationRequest, createOrganizationuccess,
    createOrganizationFailure, updateOrganization, deleteOrganizationRequest, deleteOrganizationuccess, deleteOrganizationFailure,
    updateOrganizationRequest, updateOrganizationuccess, updateOrganizationFailure, getOrganizationByIdRequest, getOrganizationByIdSuccess,
    getOrganizationByIdFailure, updateOrganizationChanges, clearOrganizationDetail, deployOrganizationRequest, deployOrganizationSuccess,
    deployOrganizationFailure, syncOrganizationRequest, syncOrganizationSuccess, syncOrganizationFailure
} = Organizationlice.actions;
export default Organizationlice.reducer;