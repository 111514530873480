import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, TableContainer, Table, TableHead, TableBody, Paper, IconButton, Box } from "@mui/material";
import { withStyles } from "@mui/styles";

// Import Components
import TableHeaderRowComponent from './tableHeaderRow.jsx';
import TableBodyRowComponent from './tableBodyRow.jsx';
import AddTableBodyRowComponent from './addTableBodyRow.jsx';
import TableHeaderComponent from './components/header/index.jsx';
import TableSearchComponent from './components/search/index.jsx';
import { LoaderComponent, NoResultComponent } from '../index';

// import Styles
import TableStyles from './style.jsx';

// import Images
import { PlusIcon } from '../../assets/svg/index.js';


const TableRoot = (props) => {
    /**
     * Define Props
     */
    const { classes, headers, haveCheckBox, data, sortBy, orderBy, parentDetail, haveSubTable, defaultHeaderCheckBoxValue, subTableColSpan,
        subTable, onClickCheckbox, onClickSorting, onClickActions, onClickAccordian, onScrollChange, onCompnentEvent, conditionDeleteShowColumn, tableData, styleType,
        onHandleChipDelete, onHandleChipAdd, onHandleChipSelect, searchData, onHandleSearchEvent, isAdd, selectComponentList, subTableHaveCheckbox,
        haveSubTableHeader, subTableActions, subTableIsAddData, onSubTableActions, onSubTableCompnentEvent, onSubTableHandleChipAdd, onSubTableHandleChipDelete, options,
        filters, title, description, isLoading, onCellClick, height, NoResultText, highlightIndex, stickyHeader, showHeader } = props;

    /**
     * Define States
     */
    const [visibleNewTableRowInput, setVisibleNewTableRowInput] = useState(false);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [showSearch, setSearch] = useState(false);
    const [columnEdited, setColumnEdited] = useState(false);

    /**
     * Handle Page Scroll for Lazy Loading
     * @param {*} event
     */
    const onScrollEvent = (event) => {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight && onScrollChange) {
            onScrollChange();
        }
    };

    // Style Type Condion
    const tableStyle = (styleType) => {
        return styleType === "collapse" ? classes.collapseTable : classes.stripedTable;
    };

    const handleChange = (item, name, value) => {
        if (!item.customFunction) {
            if (item.type === "columns") {
                let tableHeaderList = Object.assign([], tableHeaders);
                const index = tableHeaderList.findIndex((header) => header.name === name);
                if (tableHeaderList[index].isDefault) {
                    tableHeaderList = tableHeaderList.map((header) => {
                        return {
                            ...header,
                            showColumn: value
                        };
                    });
                }
                else {
                    tableHeaderList[index].showColumn = value;
                }
                setTableHeaders([...tableHeaderList]);
                setColumnEdited(true);
            } else if (item.type === "search") {
                setSearch(!showSearch);
            } else if (item.type === "add") {
                setVisibleNewTableRowInput(true);
            }
        } else {
            item.customFunction(item, value);
        }
    };

    /**
     * Load Headers
     */
    useEffect(() => {
        if (((headers.length > 0) || !tableHeaders.length) && !columnEdited) {
            const headerList = headers.map((header) => {
                return {
                    ...header,
                    showColumn: !header.hideDefault
                };
            });
            headerList.unshift({ name: "Select All", isDefault: true, showColumn: true });
            setTableHeaders([...headerList]);
        }
    }, [headers]);

    /**
     * Filter Headers
     * @param {*} data
     * @returns list
     */
    const filterHeaders = (data) => {
        return data.filter((header) => header.showColumn && !header.isDefault);
    };

    /**
     * Filter Headers using usememo
     */
    const filteredHeaders = useMemo(() => filterHeaders(tableHeaders), [tableHeaders]);

    return (
        <Grid item xs={12} className={tableStyle(styleType)}>
            {
                <Grid className="dflex alignCenter mb-1">
                    {
                        (options || filters) &&
                        <TableHeaderComponent options={options} filters={filters} headers={tableHeaders} handleChange={handleChange} title={title} description={description} />
                    }
                    {
                        isAdd &&
                        <Box sx={{ textAlign: 'right' }}>
                            <IconButton className={classes.PlusIcon} onClick={() => setVisibleNewTableRowInput(true)}>
                                <PlusIcon />
                            </IconButton>
                        </Box>
                    }
                </Grid>
            }
            <TableContainer className={classes.tableContainer} component={Paper}>
                <Table onScroll={(event) => onScrollEvent(event)} stickyHeader={stickyHeader}>
                    {
                        ((visibleNewTableRowInput) || (subTable && !haveSubTableHeader)) && showHeader &&
                        <TableHead>
                            <TableHeaderRowComponent
                                classes={classes}
                                headers={filteredHeaders}
                                haveCheckBox={haveCheckBox}
                                haveSubTable={haveSubTable}
                                defaultHeaderCheckBoxValue={defaultHeaderCheckBoxValue}
                                sortBy={sortBy}
                                orderBy={orderBy}
                                parentDetail={parentDetail}
                                onClickCheckbox={(isChecked, selectedItem, parentId) => onClickCheckbox(isChecked, selectedItem, parentId, true)}
                                onClickSorting={(sortBy, orderBy) => onClickSorting(sortBy, orderBy)}
                            />
                            {
                                showSearch &&
                                <TableSearchComponent
                                    headers={filteredHeaders}
                                    onChange={onHandleSearchEvent}
                                    searchData={searchData} />
                            }
                        </TableHead>
                    }
                    <TableBody>
                        {
                            visibleNewTableRowInput &&
                            <AddTableBodyRowComponent
                                classes={classes}
                                headers={filteredHeaders}
                                completeNewRowData={() => setVisibleNewTableRowInput(false)}
                                onHandleChipSelect={onHandleChipSelect}
                                onHandleChipDelete={onHandleChipDelete}
                                onClickActions={onClickActions}
                                haveSubTable={haveSubTable}
                                parentDetail={parentDetail}
                                selectComponentList={selectComponentList}
                            />
                        }
                        {
                            data && data.map((item, index) => {
                                return (
                                    <TableBodyRowComponent
                                        key={`bodyRow-${index}`}
                                        classes={classes}
                                        headers={filteredHeaders}
                                        data={((item instanceof Object) && item) || (tableData && tableData[item]) || {}}
                                        parentDetail={parentDetail}
                                        rowIndex={index}
                                        haveCheckBox={haveCheckBox}
                                        haveSubTable={haveSubTable}
                                        subTable={subTable}
                                        subTableColSpan={subTableColSpan}
                                        onClickCheckbox={onClickCheckbox}
                                        onClickActions={onClickActions}
                                        onClickAccordian={onClickAccordian}
                                        onCompnentEvent={onCompnentEvent}
                                        onCellClick={onCellClick}
                                        conditionDeleteShowColumn={conditionDeleteShowColumn}
                                        onHandleChipAdd={onHandleChipAdd}
                                        onHandleChipDelete={onHandleChipDelete}
                                        onHandleChipSelect={onHandleChipSelect}
                                        selectComponentList={selectComponentList || {}}
                                        subTableHaveCheckbox={subTableHaveCheckbox}
                                        haveSubTableHeader={haveSubTableHeader}
                                        subTableActions={subTableActions}
                                        subTableIsAddData={subTableIsAddData}
                                        onSubTableActions={onSubTableActions}
                                        onSubTableCompnentEvent={onSubTableCompnentEvent}
                                        onSubTableHandleChipAdd={onSubTableHandleChipAdd}
                                        onSubTableHandleChipDelete={onSubTableHandleChipDelete}
                                        highlightIndex={highlightIndex}
                                    />
                                );
                            })
                        }
                    </TableBody>
                </Table>
                {
                    data && data.length === 0 && isLoading === false && !visibleNewTableRowInput &&
                    <NoResultComponent title={NoResultText ? NoResultText : ''} height={height} />
                }
                {
                    isLoading &&
                    <LoaderComponent loaderType="circular" height={height} />
                }
            </TableContainer>
        </Grid >
    );
};

/**
 * Define Component Props
 */
TableRoot.propTypes = {
    classes: PropTypes.object,
    headers: PropTypes.array,
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    haveCheckBox: PropTypes.bool,
    defaultHeaderCheckBoxValue: PropTypes.bool,
    orderBy: PropTypes.string,
    sortBy: PropTypes.string,
    haveSubTable: PropTypes.bool,
    subTable: PropTypes.object,
    subTableColSpan: PropTypes.number,
    parentDetail: PropTypes.string,
    onClickCheckbox: PropTypes.func,
    onClickSorting: PropTypes.func,
    onClickActions: PropTypes.func,
    onClickAccordian: PropTypes.func,
    onCellClick: PropTypes.func,
    onScrollChange: PropTypes.func,
    onCompnentEvent: PropTypes.func,
    conditionDeleteShowColumn: PropTypes.string,
    tableData: PropTypes.object,
    styleType: PropTypes.string,
    onHandleChipAdd: PropTypes.func,
    onHandleChipDelete: PropTypes.func,
    onHandleChipSelect: PropTypes.func,
    onHandleSearchEvent: PropTypes.func,
    searchData: PropTypes.object,
    isAdd: PropTypes.bool,
    selectComponentList: PropTypes.object,
    subTableHaveCheckbox: PropTypes.bool,
    haveSubTableHeader: PropTypes.bool,
    subTableActions: PropTypes.array,
    subTableIsAddData: PropTypes.bool,
    onSubTableActions: PropTypes.func,
    onSubTableCompnentEvent: PropTypes.func,
    onSubTableHandleChipAdd: PropTypes.func,
    onSubTableHandleChipDelete: PropTypes.func,
    options: PropTypes.array,
    filters: PropTypes.array,
    title: PropTypes.string,
    description: PropTypes.string,
    height: PropTypes.string,
    NoResultText: PropTypes.string,
    highlightIndex: PropTypes.number,
    stickyHeader: PropTypes.bool,
    showHeader: PropTypes.bool
};

/**
 * Set Default Values
 */
TableRoot.defaultProps = {
    classes: {},
    headers: [],
    data: [],
    haveCheckBox: false,
    defaultHeaderCheckBoxValue: false,
    orderBy: 'asc',
    sortBy: "",
    haveSubTable: false,
    subTable: {},
    parentDetail: "",
    subTableColSpan: 0,
    onClickCheckbox: () => { },
    onClickSorting: () => { },
    onClickActions: () => { },
    onClickAccordian: () => { },
    onScrollChange: () => { },
    onCompnentEvent: () => { },
    onCellClick: () => { },
    conditionDeleteShowColumn: "",
    tableData: {},
    styleType: "collapse",
    onHandleChipAdd: () => { },
    onHandleChipDelete: () => { },
    onHandleChipSelect: () => { },
    onHandleSearchEvent: () => { },
    searchData: {},
    isAdd: false,
    selectComponentList: {},
    subTableHaveCheckbox: false,
    haveSubTableHeader: false,
    subTableActions: [],
    subTableIsAddData: false,
    onSubTableActions: () => { },
    onSubTableCompnentEvent: () => { },
    onSubTableHandleChipAdd: () => { },
    onSubTableHandleChipDelete: () => { },
    options: [],
    filters: [],
    title: "",
    description: "",
    isLoading: false,
    highlightIndex: -1,
    stickyHeader: false,
    showHeader: true
};

export default withStyles(TableStyles)(TableRoot);