import React from 'react';
import PropTypes from 'prop-types';
import { TextValidator } from 'react-material-ui-form-validator';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import style from "./style.jsx";

const NumberInputComponent = (props) => {

	/**
	 * Define Props
	 */
	const { classes, integeronly, noOutline, onChange, variant, fullWidth, ...rest } = props;

	/**
	 * Define on change event
	 * @param {*} event
	 */
	const handleValueChange = (event) => {
		let pattern = integeronly ? '^[+-]?[0-9]+$' : '^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$';
		if (props.pattern) {
			pattern = props.pattern;
		}
		const regex = new RegExp(pattern);
		if (event.target.value === '' || regex.test(event.target.value)) {
			onChange(event.target.value);
		}
	};

	return (
		<Grid className={`${noOutline ? classes.outLineNone : null} textBoxContainer`}>
			<TextValidator
				fullWidth={fullWidth}
				variant={variant}
				type="number"
				autoComplete={"off"}
				{...rest}
				onChange={(event) => handleValueChange(event)} />
		</Grid>
	);
};

// prop types
NumberInputComponent.propTypes = {
	onChange: PropTypes.func,
	integeronly: PropTypes.bool,
	pattern: PropTypes.string,
	classes: PropTypes.object,
	noOutline: PropTypes.bool,
	variant: PropTypes.string,
	fullWidth: PropTypes.bool
};

// default props
NumberInputComponent.defaultProps = {
	onChange: () => { },
	integeronly: true,
	pattern: "",
	classes: {},
	noOutline: false,
	variant: 'outlined',
	fullWidth: false
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
	return _.isEqual(prevProps.value, nextProps.value) && _.isEqual(prevProps.classes, nextProps.classes);
}

/**
 * Export Default Component
 */
export default withStyles(
	(theme) => ({
		...style(theme)
	}),
	{ withTheme: true }
)(NumberInputComponent);

/**
 * Export As Memo Component
 */
export const MemoNumberInputComponent = withStyles(
	(theme) => ({
		...style(theme)
	}),
	{ withTheme: true }
)(React.memo(NumberInputComponent, areEqual));