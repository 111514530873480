import appConstants from '../constants/appConstants';
import pathConstants from '../constants/pathConstants';
import _ from "lodash";
import moment from 'moment';

const { REACT_APP_CLIENT_DOMAIN } = process.env;

/**
 * Get Client Domain
 * @returns
 */
export const get_client_domain = () => {
	return REACT_APP_CLIENT_DOMAIN;
};

/**
 * Rediect to Page
 * @param {*} path
 * @param {*} state
 * @param {*} needFrontSlash
 */
export const getRedirectPath = (path, params = [], needFrontSlash = false) => {
	let fPath = _.get(pathConstants, path);
	if (needFrontSlash) {
		fPath = fPath.replace(/^\/|\/$/g, '');
	}
	params.forEach((value, index) => {
		fPath = fPath.replace(`param${index + 1}`, value);
	});
	return fPath;
};

/**
 * Create Element for Font Import
 * @param {*} href
 * @returns
 */
export const createElement = (href) => {
	const linkElement = document.createElement('link');
	linkElement.href = href;
	linkElement.type = 'text/css';
	linkElement.rel = 'stylesheet';
	return linkElement;
};

/**
 * Set Font Familt Import for inital load
 * @param {*} font_family
 */
export const setThemePalette = (font_family) => {
	const { typography } = font_family;
	const fontList = [];
	Object.keys(typography).forEach((fonts) => {
		if (fontList.indexOf(typography[fonts].fontFamily) === -1 && typography[fonts].fontFamily) { fontList.push(typography[fonts].fontFamily); }
	});
	fontList.forEach((font_family) => {
		const setFontFamily = appConstants.fontFamily.find((font) => font.name === font_family);
		if (setFontFamily && setFontFamily.url) {
			const linkElement = createElement(setFontFamily.url);
			document.querySelector('head').appendChild(linkElement);
		}
	});
};

/**
 * Handle Required Error Message Content
 * @param {*} errLabel
 * @returns
 */
export const setRequiredErrorMessage = (errLabel) => {
	return errLabel.toLowerCase() + " " + appConstants.errorMessages.requriedField;
};

/**
 * Get user
 */
export const getUser = () => {
	let token = localStorage.getItem(appConstants.localStorageKey);
	token = JSON.parse(token);
	if (token && token.user) {
		return token.user;
	}
	return null;
};

/**
 * Get user auth Token
 */
export const getToken = () => {
	let token = localStorage.getItem(appConstants.localStorageKey);
	token = JSON.parse(token);
	if (token && token.access) {
		return token.access;
	}
	return null;
};

/**
 * Get user refresh Token
 */
export const getRefreshToken = () => {
	let token = localStorage.getItem(appConstants.localStorageKey);
	token = JSON.parse(token);
	if (token && token.refresh) {
		return token.refresh;
	}
	return null;
};

/**
 * Build Required Error Message
 */
export const removeToken = () => {
	localStorage.removeItem(appConstants.localStorageKey);
};


/**
 * Convert UTC DateTime to Local DateTime
 * @param {*} datetime
 * @returns
 */
export const convertUTCtoLocalDateTime = (datetime) => {
	if (datetime) {
		const date = new Date(datetime);
		return moment(date).format("MMM DD YYYY hh:mm A");
	}
	return 'NA';
};

/**
 * Convert Number to Comma Separated
 * @param {*} x
 * @returns
 */
export const numberWithCommas = (x) => {
	if (x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	return "NA";
};

/**
 * Get Header Title
 * @param {*} path
 */
export const getHeaderTitle = (path) => {
	const sPath = path.split('/');
	const pathName = [];
	sPath.forEach((element) => {
		if (appConstants.header[element]) {
			pathName.push(appConstants.header[element]);
		}
	});
	return pathName.join(' / ');
};