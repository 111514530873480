const TableStyles = (theme) => ({
    collapseTable: {
        maxWidth: 'calc(100% - 1px) !important',
        '& .tableCell .MuiGrid-root': {
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .MuiTableRow-head': {
            background: theme.palette.greyshades.lightgrey
        },
        '& .MuiTableCell-root': {
            padding: '10px 10px',
            borderBottom: 'none',
            '& .MuiCheckbox-root': {}
        },
        '& thead': {
            position: 'sticky',
            top: 0,
            background: '#fff',
            zIndex: 1
        },
        '& .MuiTableCell-head': {
            fontSize: `${theme.palette.headers.h6.size}px !important`,
            '& .MuiTypography-root': {
                fontSize: `${theme.palette.headers.h6.size}px !important`,
                fontWeight: `${theme.palette.headers.h6.weight} !important`
            },
            '& .searchBox ': {
                padding: '0px 10px !important',
                '& .searchIconRt': {
                    fontSize: '20px'
                },
                '& .searchRtWithClear': {
                    marginRight: '-7px !important'
                }
            }
        },
        '& .MuiTableCell-body': {
            borderBottom: 'none !important',
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            '& .textBoxContainer': {
                width: '100%'
            }
        },
        '& .MuiPaper-root': {
            boxShadow: 'none !important',
            overflowX: 'unset'
        },
        '& .MuiTableBody-root': {
            border: `1px solid ${theme.palette.greyshades.lightgrey}`,
            overflowX: 'hidden'
        },
        '& .MuiCheckbox-root': {
            padding: '6px !important'
        },
        '& .MuiFormControlLabel-root .MuiTypography-root': {
            fontSize: '14px !important'
        },
        '& .CalendarIcon': {
            width: '20px !important',
            height: '20px !important',
            '& circle, ellipse, path': {
                fill: theme.palette.greyshades.darkgrey
            },
            '&:hover': {
                '& circle, ellipse, path': {
                    fill: theme.palette.secondary.main
                }
            }
        },
        '& .tableChecbox': {
            width: '0.5% !important',
            maxWidth: 36,
            paddingRight: '0px !important',
            paddingLeft: '18px',
            '& label': {
                marginRight: '0px !important'
            }

        },
        '& th.tableChecbox label': {
            marginTop: '-6px !important'
        },
        '& .MuiCollapse-wrapperInner th h6': {
        },
        '& .MuiTableRow-root .MuiTableCell-root:nth-child(2)': {
            paddingLeft: '5px !important'
        },
        '& .MuiTableRow-root .MuiTableCell-root:last-child': {
            width: '15px !important'
        },
        '& .collapseIcon': {
            width: 27,
            height: 27,
            padding: '0px !important'
        },
        '& .MuiOutlinedInput-input': {
            padding: '7px 14px 4.5px',
            background: '#fff'
        },
        '& .noOutline .MuiOutlinedInput-input': {
            background: `${theme.palette.greyshades.lightestgrey} !important`,
            borderRadius: 2
        },
        '& .MuiInput-input': {
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .noOutline': {
            '& .MuiInput-underline:before': {
                borderBottom: 'none !important'
            },
            '& :hovere': {
                '& .MuiInput-input': {
                    background: theme.palette.greyshades.lightestgrey,
                    paddingLeft: 5,
                    paddingRight: 5,
                    transition: "0.5s all"
                }
            }
        },
        '& .chipContainer': {
            width: '100%',
            '& form': {
                width: '100%'
            }
        },
        '& svg.CalendarIcon:hover': {
            '& path, circle, ellipse': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.DeleteIcon:hover': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.LinkIcon': {
            '&:hover path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.MenuVerticleIcon, svg.PlayIcon2': {
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .ChevDownIcon, .ChevUpIcon': {
            '&:hover path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        }
    },
    accordionTableRow: {
        // background: '#F5F7F8',
        background: '#fff',
        borderTop: `1px solid ${theme.palette.greyshades.lightgrey}`,
        '& .tableCell .MuiGrid-root': {
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .MuiTable-root, .MuiTableCell-body': {
            border: `1px solid ${theme.palette.greyshades.lightgrey}`
        },
        '& .MuiTableCell-body': {
            borderLeft: `none !important`,
            borderRight: `none !important`,
            '& .textBoxContainer': {
                width: '100%'
            }
        },
        '& .MuiTableRow-head': {
            background: theme.palette.greyshades.lightgrey,
            fontFamily: theme.palette.headers.h1.fontFamily,
            fontWeight: theme.palette.headers.h1.weight
        },
        '& .MuiInput-input': {
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .MuiCollapse-root .MuiTableCell-root': {
            padding: '4px 10px'
        },
        '& thead': {
            position: 'unset',
            top: 0,
            background: 'inherit',
            zIndex: 1
        },
        '& .MuiTableHead-root': {
            borderBottom: 'none !important',
            background: "#F5F7F8"
        },
        '& .MuiTableCell-head': {
            borderBottom: 'none !important',
            fontSize: `${theme.palette.headers.h6.size}px !important`,
            '& .MuiTypography-root': {
                fontSize: `${theme.palette.headers.h6.size}px !important`,
                fontWeight: `${theme.palette.headers.h6.weight} !important`
            }
        },
        '& .MuiTableBody-root': {
            border: 'none !important',
            '& .MuiTableRow-root:nth-child(even)': {
                // background: "#F5F7F8"
            }
        },
        '& .MuiCollapse-root': {
            paddingRight: 30,
            paddingLeft: 15
        },
        '& .MuiCollapse-wrapper': {
            padding: "12px 8px 20px 6px"
        },
        '& .outlineNone': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
                '&:hover': {
                    borderBottom: `1px solid ${theme.palette.greyshades.grey} !important`,
                    background: theme.palette.greyshades.lightestgrey
                }
            }
        },
        '& .noOutline': {
            '& .MuiInput-underline:before': {
                borderBottom: 'none !important'
            },
            '& :hovere': {
                '& .MuiInput-input': {
                    background: theme.palette.greyshades.lightestgrey,
                    paddingLeft: 5,
                    paddingRight: 5,
                    transition: "0.5s all"
                }
            }
        },
        '& svg.CalendarIcon:hover': {
            '& path, circle, ellipse': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.DeleteIcon:hover': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.LinkIcon': {
            '&:hover path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.MenuVerticleIcon, svg.PlayIcon2': {
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`
            }
        }
    },
    stripedTable: {
        width: '100%',
        '& .tableCell .MuiGrid-root': {
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .MuiChip-root.highChip, .MuiChip-root.mediumChip, .MuiChip-root.lowChip, .MuiChip-root.okChip': {
            background: 'transparent !important',
            padding: '0px !important'
        },
        '& .MuiTable-root': {
            border: `1px solid ${theme.palette.greyshades.lightgrey}`
        },
        '& .ListofUsers.MuiGrid-root.mb-1': {
            marginBottom: '0px !important'
        },
        /*
         * '& .MuiTable-root': {
         *     borderSpacing: '0 8px',
         *     borderCollapse: 'separate',
         *     padding: '0 3px'
         * },
         */
        '& .MuiPaper-root': {
            boxShadow: 'none'
        },
        '& .MuiInput-input': {
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .MuiInput-underline .MuiInput-input': {
            fontSize: `${theme.palette.headers.body1.size}px !important`
        },
        '& .MuiTableCell-root': {
            padding: '10px 12px',
            verticalAlign: 'middle !important'
        },
        '& .MuiOutlinedInput-input': {
            padding: '5.5px 14px',
            background: '#fff'
        },
        '& .MuiSelect-outlined': {
            background: 'transparent !important'
        },
        '& .noOutline .MuiOutlinedInput-input': {
            background: `${theme.palette.greyshades.lightestgrey} !important`,
            borderRadius: 2
        },
        '& .noOutline': {
            '& .MuiInput-underline:before': {
                borderBottom: 'none !important'
            },
            '& :hovere': {
                '& .MuiInput-input': {
                    background: theme.palette.greyshades.lightestgrey,
                    paddingLeft: 5,
                    paddingRight: 5,
                    transition: "0.5s all"
                }
            }
        },
        '& .MuiTableCell-head': {
            borderBottom: 'none !important',
            textTransform: 'capitalize',
            padding: '10px 12px',
            verticalAlign: 'top !important',
            whiteSpace: 'nowrap',
            fontSize: `${theme.palette.headers.h6.size}px !important`,
            '& .MuiTypography-root': {
                fontSize: `${theme.palette.headers.h6.size}px !important`,
                fontWeight: `${theme.palette.headers.h6.weight} !important`
            },
            '& .searchBox ': {
                padding: '0px 10px !important',
                '& .searchIconRt': {
                    fontSize: '20px'
                },
                '& .searchRtWithClear': {
                    marginRight: '-7px !important'
                }
            },
            '& .MuiSelect-select': {
                padding: '5.5px 14px !important',
                '&~.ChevDownIcon': {
                    marginRight: '10px'
                }
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette.greyshades.grey}`,
                borderRadius: `2px`
            },
            '& .MuiOutlinedInput-root': {
                '&:hover': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${theme.palette.greyshades.grey} !important`
                    }
                },
                '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${theme.palette.greyshades.grey} !important`
                    }
                }
            }
        },
        '& .MuiTableCell-body': {
            borderBottom: 'none !important',
            borderLeft: `none !important`,
            borderRight: `none !important`,
            fontSize: `${theme.palette.headers.body1.size}px !important`,
            border: `1px solid ${theme.palette.greyshades.lightgrey}`,
            '& .attributeTypeIcon': {
                color: `${theme.palette.secondary.main} !important`,
                background: theme.palette.primary.contrastText,
                padding: '2px 6px',
                height: '20px',
                marginRight: '7px',
                fontSize: '13px',
                border: `1px solid ${theme.palette.greyshades.lightgrey}`
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#c8cbcc66'
            },
            '& .textBoxContainer': {
                width: '100%'
            }
        },
        '& .MuiTableRow-head': {
            background: theme.palette.greyshades.lightgrey
        },
        '& .MuiTableCell-stickyHeader': {
            backgroundColor: `${theme.palette.greyshades.lightgrey} !important`
        },
        '& .MuiTableBody-root': {
            '& .MuiTableRow-root:nth-child(odd)': {
                // background: theme.palette.greyshades.tablegrey
                background: '#fff'
            },
            '& .MuiTableRow-root': {
                // boxShadow: '0px 1px 7px #b0bac961'
                borderBottom: `1px solid #F1F4F7 !important`
            }
        },
        '& .keyicon': {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 18,
            '& svg path': {
                fill: theme.palette.greyshades.darkgrey
            }
        },
        '& .MuiChip-root': {
            height: 26,
            '&.numberChip': {
                borderRadius: 4,
                '& .MuiChip-label': {
                    paddingLeft: 9,
                    paddingRight: 9
                }
            },
            '&.listchips': {
                background: '#fff',
                border: `1px solid ${theme.palette.greyshades.darkgrey}`
            },
            '&.success': {
                color: theme.palette.componentColors.success,
                background: `${theme.palette.componentColors.success}25`
            },
            '&.danger': {
                color: theme.palette.componentColors.danger,
                background: `${theme.palette.componentColors.danger}25`
            },
            '&.warning': {
                color: theme.palette.componentColors.warning,
                background: `${theme.palette.componentColors.warning}25`
            },
            '&.info': {
                color: theme.palette.componentColors.info,
                background: `${theme.palette.componentColors.info}25`
            },
            '&.orange': {
                color: theme.palette.componentColors.shade2,
                background: `${theme.palette.componentColors.orangebg}25`
            },
            '&.default': {
                background: theme.palette.greyshades.lightestgrey,
                border: `1px solid ${theme.palette.greyshades.lightgrey}`
            }
        },
        '& .MuiIconButton-root.active svg': {
            fill: theme.palette.componentColors.info
        },
        '& .ThunderIcon': {
            width: '11.057px',
            height: '19.661px'
        },
        '& .MuiAutocomplete-root .MuiInput-root .MuiInput-input': {
            padding: '1px 4px 1px 0px !important'
        },
        '& .chipContainer': {
            width: '100%',
            '& form': {
                width: '100%'
            }
        },
        '& svg.CalendarIcon:hover': {
            '& path, circle, ellipse': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& .DeleteIcon:hover': {
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                stroke: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.LinkIcon': {
            '&:hover path': {
                fill: `${theme.palette.secondary.main} !important`
            }
        },
        '& svg.MenuVerticleIcon, svg.PlayIcon2': {
            '&:hover': {
                fill: `${theme.palette.secondary.main} !important`
            }
        }
    },
    outlineNone: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important'
        },
        '& .MuiOutlinedInput-input': {
            paddingTop: '2.5px !important',
            paddingBottom: '2.5px !important',
            '&:hover': {
                background: theme.palette.greyshades.lightestgrey
            }
        },
        '&:hover': {
            borderBottom: `1px solid ${theme.palette.greyshades.grey} !important`
        }
    },
    colorPicker: {
        width: 22,
        height: 22,
        borderRadius: 30,
        position: 'relative',
        padding: '0px !important',
        '& .typeColor': {
            width: 22,
            border: 'none',
            height: 22,
            display: 'block',
            opacity: 0,
            position: 'absolute'
        }
    },
    tableContainer: {
        '& svg.NoResultIcon': {
            width: '140px',
            height: '140px'
        }
    },
    highlightRow: {
        '&.high': {
            background: `${theme.palette.colorThemes.high}15 !important`
        },
        '&.medium': {
            background: `${theme.palette.colorThemes.medium}15 !important`
        },
        '&.low': {
            background: `${theme.palette.colorThemes.low}15 !important`
        }
    },
    PlusIcon: {
        '& .PlusIcon:hover': {
            fill: `${theme.palette.secondary.main} !important`
        }
    }
});

export default TableStyles;