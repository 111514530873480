import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import { getConnectionTypeRequest, getConnectionTypeSuccess, getConnectionTypeFailure } from '../reducer/defaultReducer';
import { displyAlert } from "../reducer/alertReducer";

// Import Services
import { defaultService } from '../service';

/**
 * Get Connection Types
 * @returns
 */
const getConnectionTypes = (action$) => action$.pipe(
    ofType(getConnectionTypeRequest),
    mergeMap(() => {
        return from(defaultService.getConnectionTypes()).pipe(
            concatMap((res) => { return of(getConnectionTypeSuccess(res.data)); }),
            catchError((error) => { return of(getConnectionTypeFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })); })
        );
    })
);


// Export All Organization Functions
export const defaultEpic = [getConnectionTypes];