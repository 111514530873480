import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, IconButton } from "@mui/material";
import { TextValidator } from 'react-material-ui-form-validator';
import { Visibility, VisibilityOffOutlined } from "@mui/icons-material";
import style from "../../layouts/style.jsx";
import { withStyles } from "@mui/styles";
import _ from 'lodash';

const PasswordInputComponent = (props) => {
	/**
	 * Define Props
	 */
	const { classes, onChange, InputProps, value, ...rest } = props;

	/**
	 * Define States
	 */
	const [type, setType] = useState('password');

	return (
		<TextValidator
			{...rest}
			autoComplete={"off"}
			onChange={(event) => onChange(event, event.target.value)}
			className={classes.passwordBox}
			type={type}
			value={value}
			InputProps={
				{
					...InputProps,
					endAdornment: (
						<InputAdornment position="end">
							<IconButton className={"adornment"} onClick={() => setType(type === 'password' ? 'text' : 'password')}>
								{
									type === 'password' ? (
										<VisibilityOffOutlined />
									) : (
										<Visibility />
									)
								}
							</IconButton>
						</InputAdornment>
					)
				}
			}
		/>
	);
};

/**
 * Define Prop Types
 */
PasswordInputComponent.propTypes = {
	classes: PropTypes.object,
	onChange: PropTypes.func,
	InputProps: PropTypes.object,
	value: PropTypes.string.isRequired
};

// Default props
PasswordInputComponent.defaultProps = {
	classes: {},
	onChange: () => { },
	InputProps: {},
	value: ''
};


/**
 * Set Default Values
 */
PasswordInputComponent.defaultProps = {
	onChange: () => { },
	classes: {},
	value: '',
	InputProps: {}
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
	return _.isEqual(prevProps.value, nextProps.value) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
	(theme) => ({
		...style(theme)
	}),
	{ withTheme: true }
)(PasswordInputComponent);


/**
 * Export As Memo Component
 */
 export const MemoPasswordInputComponent = withStyles(
	(theme) => ({
		...style(theme)
	}),
	{ withTheme: true }
)(React.memo(PasswordInputComponent, areEqual));