import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Avatar, IconButton, MenuItem, Grid } from '@mui/material';
import PropTypes from 'prop-types';

// Import Redux
import { logoutSuccess } from '../../../redux/reducer/authReducer.js';

//components
import { PopOverComponent } from '../../index.js';
import { LogoutIcon, UserIcon2 } from '../../../assets/svg';


const Profile = (props) => {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    const { name } = useSelector((state) => state.auth.user);

    /**
     * Define State
     */
    const [anchorEl, setAnchorEl] = React.useState(null);

    /**
     * Handle Popup
     * @param {*} event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Close Popup
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Logout
     */
    const logout = () => {
        setAnchorEl(null);
        dispatch(logoutSuccess());
    };

    /**
     * Set Variables
     */
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Grid>
            <IconButton className="p5" onClick={handleClick}>
                <Avatar
                    className="profileImg"
                    alt={name}
                    sx={{ width: 21, height: 21 }}
                />
            </IconButton>
            <PopOverComponent
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={`${classes.popoverContainer} popoverContainer`}
            >
                <Grid className={classes.popoverContainer}>
                    <MenuItem onClick={handleClose}>
                        <UserIcon2 />
                        Profile
                    </MenuItem>
                    <MenuItem onClick={logout}>
                        <LogoutIcon />
                        Logout
                    </MenuItem>
                </Grid>
            </PopOverComponent>
        </Grid>
    );
};

// default props
Profile.defaultProps = {
    classes: {}
};

// prop types
Profile.propTypes = {
    classes: PropTypes.object
};

export default Profile;