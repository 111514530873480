import { startTransition, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Import styles
import { clear } from '../../redux/reducer/navigationReducer.js';

// Import Helper
import { getRedirectPath } from '../../helpers/appHelpers';

function AppNavigator(props) {

	/**
	 * Define Props
	 */
	const dispatch = useDispatch();

	/**
	 * Set State
	 */
	const { path, state, params } = useSelector((state) => state.navigation);

	/**
	 * Use Effect
	 */
	useEffect(() => {
		if (path) {
			startTransition(() => {
				dispatch(clear());
			});
		}
	}, [path]);

	if (!path) {
		return null;
	}
	return (<Navigate to={{ pathname: getRedirectPath(path, params) }} state={{ from: { ...props.location }, ...state }} />);
}

/**
 * Define Props
 */
AppNavigator.propTypes = {
	location: PropTypes.object
};

/**
 * Set Default Values
 */
AppNavigator.defaultProps = {
	location: {}
};

export default AppNavigator;