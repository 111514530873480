const style = (theme) => ({
    loadingBtn: {
'&.Mui-disabled': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`
},
'&.MuiLoadingButton-loading': {
    '& span.btnLabel': {
        display: 'none !important'
    },
    '& .MuiLoadingButton-loadingIndicator': {
        color: '#fff !important'
    }
}
    },
    circleLoader: {
        background: "transparent !important",
        color: "#B0BAC9 !important",
        padding: "8px 0px !important",
        minWidth: "40px !important",
        height: "38px !important",
        '&.Mui-disabled': {
            background: "transparent !important",
            '& .MuiLoadingButton-loadingIndicator': {
                color: "#B0BAC9 !important"
            }
        },
        '& .MuiLoadingButton-loadingIndicator': {
            color: "#B0BAC9 !important"
        }
    }
});
export default style;