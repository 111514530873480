import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

// Import styles
import AlertStyles from './style.jsx';

function DialogueBox(props) {

    /**
     * Define Props
     */
    const { classes, title, message, data, onConfirm, onCancel, optionButtonContent, onOptionDialogButtonClick, primaryText, purgeText } = props;


    return (
        <Dialog
            open
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.dialog}
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={"actions"}>
                <Grid container justifyContent={`${optionButtonContent && optionButtonContent.show ? 'space-between' : 'flex-end'}`}>
                    {
                        optionButtonContent && optionButtonContent.show &&
                        <Button variant="outlined" color="primary" size="small" onClick={() => onOptionDialogButtonClick("purge", data)}>
                            {purgeText ? purgeText : `Yes & Purge`}
                        </Button>
                    }
                    <Grid>
                        <Button disableElevation color="primary" variant="contained" size="small" className="mr-1 cancelBtn" onClick={() => onCancel(data)}>
                            Cancel
                        </Button>
                        <Button disableElevation color="primary" variant="contained" size="small" onClick={() => onConfirm("delete", data)}>
                            {primaryText ? primaryText : `Yes`}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

DialogueBox.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    message: PropTypes.string,
    primaryText: PropTypes.string,
    purgeText: PropTypes.string,
    data: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    optionButtonContent: PropTypes.object,
    onOptionDialogButtonClick: PropTypes.func
};

DialogueBox.defaultProps = {
    classes: {},
    title: '',
    message: '',
    primaryText: '',
    purgeText: '',
    data: {},
    optionButtonContent: {
        show: false,
        title: '',
        color: 'secondary'
    },
    onConfirm: () => { },
    onCancel: () => { },
    onOptionDialogButtonClick: () => { }

};


export default withStyles(AlertStyles)(DialogueBox);