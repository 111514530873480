import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

//  Import Styles
import NoResultStyle from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

//Cosntants

// import appConstants from '../../constants/appConstants.js';


function CircularProgressLoader(props) {
    return (
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant="determinate"
                sx={
                    {
                        color: (theme) =>
                            theme.palette.grey[
                            theme.palette.mode === 'light' ? 200 : 800
                            ]
                    }
                }
                size={40}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={
                    {
                        color: (theme) =>
                            (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                        animationDuration: '550ms',
                        position: 'absolute',
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round'
                        }
                    }
                }
                size={40}
                thickness={4}
                {...props}
            />
        </Box>
    );
}

function LoaderComponent(props) {
    const { classes, height, loaderType, cardHeight, xsCard, xlCard, ...rest } = props;

    return (
        <Grid
            container
            spacing={4}
            justifyContent={
                `${(loaderType === 'circular' || loaderType === 'attachment') ? 'center' : 'flex-start'
                }`
            }
            alignItems={`${(loaderType === 'circular' || loaderType === 'attachment') ? 'center' : 'baseline'}`}
            sx={{ height: height }}
            className={`${classes.loaderContainer} loaderContainer`}
            direction={`${(loaderType === 'circular' || loaderType === 'attachment') ? 'column' : 'row'}`}
            {...rest}
        >
            {
                loaderType === 'circular' && (
                    <Grid className={`${classes.circularContainer} circularContainer`}>
                        <CircularProgressLoader />
                    </Grid>
                )
            }
             {
                loaderType === 'attachment' && (
                    <Grid className={`${classes.attachmentContainer} circularContainer`}>
                        <CloudUploadOutlinedIcon className={classes.attachmentUpoadIcon} />
                        <Typography variant="body1" className={classes.textSecondary}>
                        Attachment loading...
                        </Typography>
                    </Grid>
                )
            }
            {
                loaderType === 'card' &&
                [...Array(5)].map((elementInArray, index) => (
                    <Grid item xs={xsCard} xl={xlCard} key={`loader-card-${index}`}>
                        <Skeleton
                            variant="rectangular"
                            width={'100%'}
                            height={cardHeight}
                        />
                        <Box sx={{ pt: 0.5 }}>
                            <Skeleton />
                            <Skeleton width="60%" />
                        </Box>
                    </Grid>
                ))
            }
            {
                loaderType === 'table' && (
                    <TableContainer sx={{ padding: "32px 40px 0px 50px" }}>
                        <Table aria-label="simple table">
                            <TableBody>
                                {
                                    [...Array(5)].map((row, index) => (
                                        <TableRow
                                            key={`loader-table-${index}`}>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </Grid>
    );
}

// default props
LoaderComponent.defaultProps = {
    classes: {},
    loaderType: 'circular',
    cardHeight: '150px',
    xsCard: 4,
    xlCard: 3
};

// prop types
LoaderComponent.propTypes = {
    classes: PropTypes.object,
    height: PropTypes.string,
    loaderType: PropTypes.string,
    cardHeight: PropTypes.string,
    xsCard: PropTypes.number,
    xlCard: PropTypes.number
};

export default withStyles(
    (theme) => ({
        ...NoResultStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(LoaderComponent);