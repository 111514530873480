import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { FormControlLabel, Switch } from '@mui/material';

// Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';

function SwitchComponent(props) {

    /**
     * Define Props
     */
    const { classes, size, label, checked, disabled, handleChange } = props;

    return (
        <FormControlLabel
            className={classes.switchContainer}
            control={<Switch size={size} disabled={disabled} checked={checked} onClick={() => handleChange(!checked)} />}
            label={label} />
    );
}

// default props
SwitchComponent.defaultProps = {
    classes: {},
    size: "",
    label: "",
    checked: false,
    disabled: false,
    handleChange: () => { }
};

// prop types
SwitchComponent.propTypes = {
    classes: PropTypes.object,
    size: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(SwitchComponent);