const urlConstants = {
    auth: {
        login: 'auth/login/',
        logout: 'auth/logout/',
        forgotPassword: 'auth/forgotpassword/',
        resetPassword: 'auth/reset_password/',
        changePassword: 'auth/change_password/'
    },
    organization: {
        url: 'organization/',
        deploy: 'organization/deploy/',
        sync: 'organization/sync/'
    },
    default: {
        url: 'default/'
    }
};

export default urlConstants;