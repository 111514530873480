import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, FormControlLabel, Checkbox, TableSortLabel, Typography } from "@mui/material";
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const TableHeaderRowComponent = (props) => {

    /**
     * Define Props
     */
    const { headers, haveCheckBox, haveSubTable, defaultHeaderCheckBoxValue, parentDetail, sortBy, orderBy, onClickSorting, onClickCheckbox } = props;

    /**
     * Handle Checkbox Event
     */
    const handleCheckBoxClick = (event, selectedItem) => {
        onClickCheckbox(event.target.checked, selectedItem, parentDetail);
    };

    return (
        <TableRow key="tableHeaderRow">
            {
                haveCheckBox &&
                <TableCell key={`headerRowCell-checkbox`} className={"tableChecbox"}>
                    <FormControlLabel control={<Checkbox checked={defaultHeaderCheckBoxValue} onChange={(event) => handleCheckBoxClick(event, null)} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />} />
                </TableCell>
            }
            {
                headers && headers.map((header, index) => {
                    return (
                        <TableCell key={`headerRowCell-${index}`} sx={{ textAlign: header.align || 'left' }}>
                            <Typography variant="h6">
                                {
                                    header.sorting ?
                                        <TableSortLabel
                                            key={`headerRowLabel-${index}`}
                                            active={sortBy === header.key}
                                            direction={orderBy}
                                            onClick={() => onClickSorting(header.key, orderBy === 'asc' ? 'desc' : 'asc')}
                                        >
                                            {header.name}
                                        </TableSortLabel>
                                        : header.name
                                }
                            </Typography>
                        </TableCell>
                    );
                })
            }
            {haveSubTable && <TableCell key={`headersub-checkbox`} />}
        </TableRow>
    );
};

/**
 * Set Component Props
 */
TableHeaderRowComponent.propTypes = {
    headers: PropTypes.array,
    haveCheckBox: PropTypes.bool,
    haveSubTable: PropTypes.bool,
    defaultHeaderCheckBoxValue: PropTypes.bool,
    sortBy: PropTypes.string,
    orderBy: PropTypes.string,
    parentDetail: PropTypes.string,
    onClickSorting: PropTypes.func,
    onClickCheckbox: PropTypes.func
};


/**
 * Set Default Values
 */
TableHeaderRowComponent.defaultProps = {
    headers: [],
    haveCheckBox: true,
    haveSubTable: false,
    defaultHeaderCheckBoxValue: false,
    sortBy: "",
    orderBy: "",
    parentDetail: "",
    onClickSorting: () => { },
    onClickCheckbox: () => { }
};

export default TableHeaderRowComponent;