const HomeStyle = (theme) => ({
    landinPageContainer: {
        padding: '5px 20px 30px'
    },
    generalPageContainer: {
        padding: '24px 30px 30px'
    },
    sectionContainer: {
        paddingTop: '0px !important',
        paddingBottom: '10px !important'
    },
    headerConatiner: {
        background: theme.palette.greyshades.lightestgrey,
        padding: '14px 24px',
        '& .selectComponent .instanceType.MuiInputBase-root': {
            margin: 0
        }
    },
    actionConatiner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    bodyContainer: {
        padding: 24,
        paddingBottom: 38,
        '& svg.CalendarIcon path, svg.CalendarIcon circle, svg.CalendarIcon ellipse': {
            fill: `${theme.palette.greyshades.darkgrey} !important`
        },
        '& .calendarIcon:hover': {
            '& circle, ellipse, path': {
                fill: theme.palette.secondary.main
            }
        }
    },
    CalendarIcon: {
        '&:hover': {
            '& svg.CalendarIcon circle, svg.CalendarIcon ellipse, svg.CalendarIcon path': {
                fill: `${theme.palette.secondary.main} !important`
            },
            background: 'red'
        }
    },
    rangeFields: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiOutlinedInput-root': {
            height: '2rem !important',
            fontSize: '13px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '10px 6px',
            textAlign: 'center'
        },
        '& .MuiInputAdornment-root .MuiButtonBase-root': {
            padding: '2px'
        },
        '& fieldset': {
            borderColor: 'transparent !important'
        },
        '& .Mui-focused fieldset': {
            border: 'none !important'
        },
        '& .MuiInputAdornment-root': {
            marginLeft: 0
        },
        '& .rangeHypen': {
            marginLeft: 8,
            marginRight: 8
        }
    },
    orgAddContainer: {
        padding: 30,
        paddingTop: '0px !important',
        height: '100%',
        '& .header': {
            position: 'sticky',
            top: 0,
            paddingBottom: 16,
            background: '#fff',
            zIndex: 99,
            paddingTop: 30,
            '& .CloseIcon': {
                width: '24px',
                height: '24px',
                '&:hover path': {
                    fill: theme.palette.secondary.main
                }
            }
        }
    },
    orgAddContainerInner: {
        width: '100%',
        height: 'calc(100% - 10px)'
    },
    addEditContainer: {
        padding: '5px 20px 50px'
    },
    actions: {
        position: 'fixed',
        bottom: '0px',
        right: '0px',
        background: '#fff',
        padding: 30,
        width: '100%'
    }
});

export default HomeStyle;