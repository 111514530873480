import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';

//Components
import { Button, Grid, Typography } from '@mui/material';

// Import Styles and Image
import DQLabsLogo from '../../assets/img/dqlabs.png';
import AuthenticationStyle from './style.jsx';
import LayoutStyle from '../../layouts/style.jsx';
import { PasswordInputComponent, LoaderButtonComponent } from '../../components/index.js';

// Import Helpers
import { setRequiredErrorMessage } from '../../helpers/appHelpers.js';
import appConstants from '../../constants/appConstants.js';

function ResetPassword(props) {
    /**
     * Define Props
     */
    const { classes } = props;

    /**
     * Define States
     */
    const [formData, setFormData] = useState({
        password: '',
        confirmpassword: ''
    });
    const { password, confirmpassword } = formData;

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { isLoading } = useSelector((state) => state.auth, shallowEqual);


    /**
     * Check Password Policy
     */
    useEffect(() => {
        ValidatorForm.addValidationRule('passwordPolicy', () => {
            if (password.length < 6) {
                return false;
            } else if (password.length > 50) {
                return false;
            } else if (password.search(/\d/) === -1 && password.search(/[!@#$^&*()_]/) === -1) {
                return false;
            } else if (password.search(/[a-z]/) === -1) {
                return false;
            } else if (password.search(/[A-Z]/) === -1) {
                return false;
            } return true;
        });
    }, [password]);

    // Check Password and Confirm Password Match
    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', () => {
            if (confirmpassword !== password) {
                return false;
            }
            return true;
        });
    }, [password, confirmpassword]);


    /**
     * Handle Textbox Changes
     */
    const handleChange = (property, value) => {
        setFormData((formData) => ({ ...formData, [property]: value }));
    };

    /**
     * Submit Form
     * @param {*} data
     */
    const handleSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <ValidatorForm noValidate onSubmit={handleSubmit}>
            <Grid className={`${classes.loginBox}`}>
                <Grid container spacing={5}>
                    <Grid item xs={12} align="center">
                        <img
                            alt="logo"
                            src={DQLabsLogo}
                            className={classes.loginLogo}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" className="mb-2">
                            {appConstants.labels.authentication.resetPassword}
                        </Typography>
                        <PasswordInputComponent
                            id="form_txt_newpassword"
                            name={'password'}
                            value={password}
                            fullWidth
                            placeholder={appConstants.labels.authentication.newPassword}
                            variant="outlined"
                            size="medium"
                            validators={['required', 'passwordPolicy']}
                            errorMessages={[setRequiredErrorMessage(appConstants.labels.authentication.newPassword), appConstants.errorMessages.passwordPolicy]}
                            onChange={(event, value) => handleChange(event.target.name, value)}
                            inputProps={{ maxLength: 100 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordInputComponent
                            id="form_txt_confirmpassword"
                            name={'confirmpassword'}
                            value={confirmpassword}
                            fullWidth
                            placeholder={appConstants.labels.authentication.confirmPassword}
                            variant="outlined"
                            size="medium"
                            validators={['required', 'isPasswordMatch']}
                            errorMessages={[setRequiredErrorMessage(appConstants.labels.authentication.confirmPassword), appConstants.errorMessages.isPasswordMatch]}
                            onChange={(event, value) => handleChange(event.target.name, value)}
                            inputProps={{ maxLength: 100 }}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.mt2}>
                        <Grid
                            container
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <LoaderButtonComponent
                                size={'large'}
                                isLoading={isLoading}>
                                {appConstants.labels.authentication.resetPassword}
                            </LoaderButtonComponent>
                            <Grid align="right">
                                <Button
                                    variant="text"
                                    className={classes.linkBtn}
                                    disableRipple
                                >
                                    <Link to="/login">
                                        {appConstants.labels.authentication.backtoLogin}
                                    </Link>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"h6"}>
                            Password requirements:
                        </Typography>
                        <Typography variant={"body1"}>
                            Min 6 characters which include 1 uppercase letter, 1 lowercase letter and 1 number or symbol [!@#$^&*()_]
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
}

// default props
ResetPassword.defaultProps = {
    classes: {}
};

// prop types
ResetPassword.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...AuthenticationStyle(theme),
        ...LayoutStyle(theme)
    }),
    { withTheme: true }
)(ResetPassword);