const style = (theme) => ({
  option: {
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: `${theme.palette.secondary.main}15 !important`,
      borderColor: 'transparent'
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: `${theme.palette.secondary.main}15 !important`,
      borderColor: 'transparent'
    },
    '&:hover': {
      color: `${theme.palette.secondary.main} !important`
    },
    '& .MuiAutocomplete-clearIndicator svg': {
      width: '18px',
      height: '18px',
      fill: theme.palette.greyshades.darkgrey,
      marginTop: '2px'
    },
    '& .MuiAutocomplete-popupIndicator': {
      marginTop: 2
    },
    '& .ChevDownIcon': {
      width: '13px',
      height: '13px'
    }
  }
});
export default style;