import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { TextValidator } from 'react-material-ui-form-validator';

// Import Styles
import style from "./style.jsx";

const TextComponent = (props) => {
    /**
     * Define Props
     */
    const { noOutline, classes, value, name, isRequired, onChange, onKeyDown, placeholder, ...rest } = props;


    const handleChange = (event) => {
        onChange(event);
    };

    return (
        <Grid className={`${noOutline ? classes.outLineNone : null} ${noOutline ? 'noOutline' : ''} textBoxContainer`}>
            <TextValidator
                autoComplete={"off"}
                value={value}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                name={name}
                required={isRequired}
                placeholder={placeholder}
                className={classes.textValidatorContainer}
                errorMessages={[`${name} is required`]}
                sx={{ width: "100%" }}
                {...rest}
            />
        </Grid>
    );
};

/**
 * Define Props
 */
TextComponent.propTypes = {
    onChange: PropTypes.func,
    classes: PropTypes.object,
    noOutline: PropTypes.bool,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    onKeyDown: PropTypes.func,
    isRequired: PropTypes.bool
};

/**
 * Set Default Values
 */
TextComponent.defaultProps = {
    onChange: () => { },
    classes: {},
    noOutline: false,
    value: ''
};


export default withStyles(
    (theme) => ({
        ...style(theme)
    }),
    { withTheme: true }
)(TextComponent);