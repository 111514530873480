const style = (theme) => ({
    outLineNone: {
        '& .MuiSelect-select': {
            paddingTop: '2.5px !important',
            paddingBottom: '2.5px !important',
            paddingRight: '32px !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important'
        },
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
            borderBottom: `1px solid ${theme.palette.greyshades.grey} !important`
            }
        }
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 10px',
        boxShadow: 'none !important',
        border: `1px solid ${theme.palette.greyshades.grey}`,
        borderRadius: '3px !important',
        '& .searchIcon': {
            fill: theme.palette.greyshades.darkgrey,
            marginRight: 5
        },
        '& input::placeholder': {
            color: `${theme.palette.greyshades.darkgrey} !important`,
            opacity: 1
        },
        '& .visibleClear': {
            visibility: 'visible !important'
        },
        '& .hiddenClear': {
            visibility: 'hidden !important'
        },
        '& .searchRtWithClear': {
            marginRight: '-3px !important'
        },
        '& .searchIconRt': {
            fill: theme.palette.greyshades.darkgrey,
            position: 'absolute',
            right: 8
        }
    },
    appLogo: {
        filter: 'grayscale(1)',
        opacity: 0.2,
        marginRight: 15,
        width: '26px',
        height: '26px',
        objectFit: 'cover'
    },
    textValidatorContainer: {
        width: '100%'
    }
});

export default style;