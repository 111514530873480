// Import API Helper
import { postRequest, getRequest, deleteRequest, putRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get Organization
 * @returns
 */
async function getOrganization() {
  const response = await getRequest(`${urlConstants.organization.url}`);
  return response;
}

/**
 * Get Organization By Id
 * @returns
 */
async function getOrganizationById(id) {
  const response = await getRequest(`${urlConstants.organization.url}${id}/`);
  return response;
}

/**
 * Create Organization
 * @param {*} params
 * @returns
 */
async function createOrganization(params) {
  const response = await postRequest(`${urlConstants.organization.url}`, params);
  return response;
}

/**
 * update Organization
 * @param {*} applicationId
 * @param {*} params
 * @returns
 */
async function updateOrganization(id, params) {
  const response = await putRequest(`${urlConstants.organization.url}${id}/`, params);
  return response;
}

/**
 * Delete Organization
 * @param {*} applicationId
 * @returns
 */
async function deleteOrganization(id, purge) {
  const response = await deleteRequest(`${urlConstants.organization.url}${id}/?purge=${purge}`);
  return response;
}

/**
 * Deploy Organization
 * @param {*} params
 * @returns
 */
async function deployOrganization(params) {
  const response = await postRequest(`${urlConstants.organization.deploy}`, params);
  return response;
}

/**
 * Sync Organization
 * @param {*} params
 * @returns
 */
async function syncOrganization(params) {
  const response = await postRequest(`${urlConstants.organization.sync}`, params);
  return response;
}


/**
 * Export All Service Functions
 */
export const organizationService = {
  getOrganization, getOrganizationById, createOrganization, deleteOrganization, updateOrganization, deployOrganization,
  syncOrganization
};