// Default Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TableCell, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';

// Import Component
import { SearchComponent } from '../../../textBox/index.js';
import { SelectComponent } from '../../../index';

//Import Styles
import style from './style.jsx';


const TableSearchComponent = (props) => {
    /**
     * Define Props
     */
    const { headers, searchData, onChange, classes } = props;

    const renderSearchElement = (elementType, searchValue, list, property) => {
        switch (elementType) {
            case 'select':
                return (<SelectComponent value={searchValue} list={list} onSelectChange={(value) => onChange(property, value)} />);
            default:
                return (<SearchComponent searchPosition="right" placeholder="Search" value={searchValue} onChange={(value) => onChange(property, value)} delayTimeOut={500} />);
        }
    };

    return (
        <TableRow className={classes.searchHeader}>
            {
                headers.map((header, index) =>
                    <TableCell key={`search_cell_${index}`}>
                        {
                            header.isSearch &&
                            <Grid>
                                {renderSearchElement(header.searchComponent, searchData[header.searchKey] || "", header.list, header.searchKey)}
                            </Grid>
                        }
                    </TableCell>
                )
            }
        </TableRow>
    );
};

/**
 * Define Component Props
 */
TableSearchComponent.propTypes = {
    headers: PropTypes.array,
    searchData: PropTypes.object,
    onChange: PropTypes.func,
    classes: PropTypes.object
};

/**
 * Set Default Values
 */
TableSearchComponent.defaultProps = {
    headers: [],
    searchData: {},
    onChange: () => { }
};

export default withStyles((theme) => ({
    ...style(theme)
}))(TableSearchComponent);