import React from 'react';
import PropTypes from 'prop-types';

//  Import Components
import { NumberInputComponent, TextBoxComponent } from '../index.js';

const ValueEditorComponent = (props) => {
    /**
     * Define Props
     */
    const { value, type, name, onChange, onKeyDown, placeholder, ...rest } = props;

    const bindTextBox = () => {
        const datatype = type || "text";
        switch (datatype) {
            case 'numeric':
                return (
                    <NumberInputComponent variant="outlined" value={value} integeronly={false} onChange={onChange} placeholder={placeholder} {...rest} />
                );
            case 'integer':
                return (
                    <NumberInputComponent variant="outlined" value={value} integeronly onChange={onChange} placeholder={placeholder} {...rest} />
                );
            default:
                return (
                    <TextBoxComponent
                        noOutline
                        placeholder={placeholder}
                        value={value}
                        name={name}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        {...rest}
                    />
                );
        }
    };

    return (
        <React.Fragment>
            {bindTextBox()}
        </React.Fragment>
    );
};

// default props
ValueEditorComponent.defaultProps = {
    value: "",
    type: "text",
    placeholder: 'Enter a value',
    onChange: () => { },
    noOutline: false
};

// prop types
ValueEditorComponent.propTypes = {
    value: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    name: PropTypes.string,
    variant: PropTypes.string,
    noOutline: PropTypes.bool
};

export default (ValueEditorComponent);