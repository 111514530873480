import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link, Navigate } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid } from '@mui/material';

//Components
import { MemoTextBoxComponent, PasswordInputComponent, LoaderButtonComponent } from '../../components/index.js';

// Import Styles
import AuthenticationStyle from './style.jsx';
import LayoutStyle from '../../layouts/style.jsx';

// Import Images
import DQLabsLogo from '../../assets/img/dqlabs.png';

// Import Helpers
import { setRequiredErrorMessage } from '../../helpers/appHelpers.js';
import appConstants from '../../constants/appConstants.js';
import { Crypto } from '../../helpers/index.js';

// Import Actions
import { loginRequest } from '../../redux/reducer/authReducer.js';


function Login(props) {
    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    /**
     * Resux Select Action
     * @param {*} event
     */
    const { isLoggedIn } = useSelector((state) => state.auth, shallowEqual);

    /**
     * Define States
     */
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const { email, password } = formData;


    /**
     * Handle Textbox Changes
     */
    const handleChange = useCallback((property, value) => {
        setFormData((formData) => ({ ...formData, [property]: value }));
    }, [formData]);

    /**
     * Submit Form
     * @param {*} data
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        const encryptDecrypt = new Crypto();
        const requestParams = {
            email: encryptDecrypt.encrypt(formData.email),
            password: encryptDecrypt.encrypt(formData.password)
        };
        dispatch(loginRequest(requestParams));
    };

    /**
     * Navigate to Home Page after Login
     */
    if (isLoggedIn || localStorage.getItem("user")) {
        return <Navigate to="/organization" />;
    }

    return (
        <ValidatorForm noValidate onSubmit={handleSubmit}>
            <Grid className={`${classes.loginBox}`}>
                <Grid container spacing={5}>
                    <Grid item xs={12} align="center">
                        <img
                            alt="logo"
                            src={DQLabsLogo}
                            className={classes.loginLogo}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MemoTextBoxComponent
                            id="form_text_email"
                            name={'email'}
                            value={email}
                            fullWidth
                            placeholder={appConstants.labels.authentication.email}
                            variant="outlined"
                            size="medium"
                            validators={['required', 'isEmail']}
                            errorMessages={
                                [
                                    setRequiredErrorMessage(appConstants.labels.authentication.email),
                                    appConstants.errorMessages.invalidMail
                                ]
                            }
                            inputProps={{ maxLength: 100 }}
                            valOnChange
                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordInputComponent
                            id="form_txt_password"
                            name={'password'}
                            value={password}
                            fullWidth
                            placeholder={appConstants.labels.authentication.password}
                            variant="outlined"
                            size="medium"
                            validators={['required']}
                            errorMessages={[setRequiredErrorMessage(appConstants.labels.authentication.password)]}
                            inputProps={{ maxLength: 100 }}
                            onChange={(event, value) => handleChange(event.target.name, value)}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.mt2}>
                        <Grid container justifyContent={'space-between'} alignItems="center">
                            <LoaderButtonComponent
                                size={'large'}
                                isLoading={false}>
                                {appConstants.labels.authentication.login}
                            </LoaderButtonComponent>
                            <Grid align="right">
                                <Button
                                    variant="text"
                                    className={classes.linkBtn}
                                    disableRipple
                                >
                                    <Link to="/forgotpassword">
                                        {appConstants.labels.authentication.forgotPassword}
                                    </Link>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
}

// default props
Login.defaultProps = {
    classes: {}
};

// prop types
Login.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...AuthenticationStyle(theme),
        ...LayoutStyle(theme)
    }),
    { withTheme: true }
)(Login);