import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';

// Components
import { HeaderComponent } from '../../components/index.js';

// Import Styles
import Styles from './styles.jsx';

// Import Reducer
import { getConnectionTypeRequest } from '../../redux/reducer/defaultReducer';

function PrivateLayoutComponent({ component: Component, ...props }) {

    /**
     * Define Props
     */
    const { classes } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getConnectionTypeRequest());
    }, []);


    return (
        <Grid container className={classes.privateLayout}>
            <HeaderComponent />
            <Grid item xs={12} className={classes.privateLayoutContainer} id="private">
                <Component />
            </Grid>
        </Grid>
    );
}

// prop types
PrivateLayoutComponent.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.elementType
};

export const PrivateLayout = withStyles(Styles)(PrivateLayoutComponent);