// Default Imports
import React from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Import Styles
import style from "./style.jsx";

// Import Icons
import { DeleteIcon } from '../../../assets/svg/index.js';
import PopOverComponent from '../../popOver/index.jsx';
import AcceptRejectIcon from './acceptRejectIcon.jsx';

const ListChipItems = (props) => {
    /**
     * Define Props
     */
    const { classes, open, anchorEl, onClose, data, labelKey, editable, handleDelete, onChipSelect, acceptActions, onHandleAcceptAction } = props;

    /**
     * Returns the lable value based on the given key
     * @param {*} item
     * @returns
     */
    const getLabel = (item) => {
        return labelKey ? item[labelKey] : item;
    };

    return (
        <PopOverComponent
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            className="pt-1 pb-1"
        >
            <Grid className="p-1">
            {
                data && data.map((item, index) =>
                    <Grid
                        container
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        key={`data_${index}`}
                        className="mb2"
                    >
                        <Typography variant="subtitle1" onClick={(event) => onChipSelect(item, event)}>
                            {getLabel(item)}
                        </Typography>
                        {
                            !acceptActions || (acceptActions && item.approval_id) && editable &&
                            <IconButton className={classes.pattenDelete} onClick={() => handleDelete(item, index)}>
                                <DeleteIcon />
                            </IconButton>
                        }
                        { acceptActions && !item.approval_id && <AcceptRejectIcon onHandleAction={(status) => onHandleAcceptAction(status, item)} size="normal" /> }
                    </Grid>
                )
            }
            </Grid>
        </PopOverComponent>
    );
};


/**
 * Define Prop Types
 */
ListChipItems.propTypes = {
    classes: PropTypes.object,
    labelKey: PropTypes.string,
    data: PropTypes.array,
    open: PropTypes.bool,
    editable: PropTypes.bool,
    handleDelete: PropTypes.func,
    anchorEl: PropTypes.object,
    onClose: PropTypes.func,
    onChipSelect: PropTypes.func,
    onHandleAcceptAction: PropTypes.func,
    acceptActions: PropTypes.bool
};

/**
 * Set Default Values
 */
ListChipItems.defaultProps = {
    classes: {},
    labelKey: "",
    data: [],
    editable: false,
    open: false,
    anchorEl: null,
    handleDelete: () => { },
    onClose: () => { },
    onChipSelect: () => { },
    onHandleAcceptAction: () => { },
    acceptActions: false
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles((theme) => ({
    ...style(theme)
}))(React.memo(ListChipItems, areEqual));