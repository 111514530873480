import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextField from '@mui/material/TextField';

// import Components
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const DatePickerComponent = (props) => {
    /**
     * Define Props
     */
    const { value, onChange, format, variant, ...rest } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                value={moment(value).format(format || "YYYY-MM-DD")}
                onChange={
                    (date) => {
                        onChange(moment(date).format(format || "YYYY-MM-DD"));
                    }
                }
                renderInput={(params) => <TextField variant={variant} {...params} {...rest} />}
                inputFormat={"yyyy-MM-dd"}
                {...rest}
            />
        </LocalizationProvider>
    );
};


// default props
DatePickerComponent.defaultProps = {
    value: "",
    onChange: () => { },
    label: "",
    format: "YYYY-MM-DD",
    variant: 'standard'
};

// prop types
DatePickerComponent.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    onChange: PropTypes.func,
    label: PropTypes.string,
    format: PropTypes.string,
    variant: PropTypes.string
};

export default (DatePickerComponent);