
const pathConstants = {
    auth: {
        login: '/'
    },
    home: {
        root: "/organization",
        add: "/organization/add",
        edit: "/organization/edit/param1"
    }
};

export default pathConstants;