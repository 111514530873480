const style = (theme) => ({
    navbar: {
        height: '80px',
        '& .navRightside': {
            display: 'flex',
            alignItems: 'center',
            padding: '8px 20px 8px 20px',
            '& svg': {
                fill: theme.palette.greyshades.darkgrey
            },
            '& svg:hover ': {
                fill: theme.palette.secondary.main
            },
            '& .profileImg': {
                margin: '0px',
                fontSize: 16,
                background: `${theme.palette.secondary.main}90`
            },
            '& .AlertsIcon path': {
                fill: theme.palette.greyshades.darkgrey
            },
            '& .AlertsIcon': {
                width: '23px',
                height: '23px'
            },
            '& .NotificationIcon:hover #Path_1191': {
                fill: theme.palette.secondary.main
            },
            '& .PlusIcon': {
                width: '24px',
                height: '24px'
            }
        }
    },
    popoverContainer: {
        '& .MuiMenuItem-root': {
            paddingRight: '40px',
            '&:hover': {
                color: `${theme.palette.secondary.main} !important`,
                '& svg path': {
                    fill: theme.palette.secondary.main
                }
            },
            '& svg': {
                marginRight: '10px'
            }
        }
    },
    backNavigation: {
        // backgroundColor: `${theme.palette.greyshades.darkgrey} !important`,
        borderRadius: '4px !important',
        width: 38,
        height: 38,
        '& svg path': {
            fill: theme.palette.greyshades.darkgrey
        }
    },
    appLogo: {
        width: 38,
        height: 38,
        padding: '11px 20px 11px 20px'
    },
    logoSection: {
        position: 'relative',
        display: 'flex',
        alignItems: " center"
    },
    logoSectionDiv: {
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        alignItems: " center",
        '&:hover': {
            '& .backNavigation': {
                visibility: 'visible',
                left: 60,
                opacity: 1
            },
            '& .appLogo': {
                opacity: 1,
                transition: '0.5s',
                width: 38
            }
        },
        '& .backNavigation': {
            visibility: 'hidden',
            transition: "0.5s",
            position: 'absolute',
            left: 40,
            opacity: 0,
            top: '11px !important'
        }
    },
    globalSearch: {
        padding: '8px 20px 8px 20px',
        '& input': {
            minWidth: 500
        }
    },
    pageTitle: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 30,
        height: 80
    }
});

export default style;