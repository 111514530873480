import axios from "axios";

// Import helpers
import { getToken, removeToken } from "./appHelpers";
import { navigate } from "../redux/reducer/navigationReducer";

const { REACT_APP_API_ENDPOINT } = process.env;

/**
 * Prepares headers for the request
 */
const getHeaders = (isFormData = false) => {
    const token = getToken();
    const headers = {};
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    if (isFormData) {
        headers["Content-Type"] = "multipart/form-data";
    } else {
        headers["Content-Type"] = "application/json";
    }
    return headers;
};

/**
 * api make request
 */
const makeRequest = async (endpoint, verb, data, isFormData = false, responseType = "json", cancellationToken = null) => {
    const requestOptions = {
        method: verb,
        url: endpoint,
        headers: getHeaders(isFormData),
        responseType: responseType,
        data,
        signal: cancellationToken?.signal
    };
    return axios(requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status === 401) {
                removeToken();
                navigate({ path: "/", state: {} });
            }
            throw error;
        });
};

/**
 * GET request
 */
export const getRequest = async (endpoint, cancellationToken) => {
    return makeRequest(`${REACT_APP_API_ENDPOINT}/${endpoint}`, "GET", {}, null, cancellationToken);
};

/**
 * PUT requests
 */
export const putRequest = async (endpoint, body, isFormData, cancellationToken) => {
    return makeRequest(`${REACT_APP_API_ENDPOINT}/${endpoint}`, "PUT", body, isFormData, null, cancellationToken);
};

/**
 * POST request
 */
export const postRequest = async (endpoint, body, isFormData, responseType, cancellationToken) => {
    return makeRequest(`${REACT_APP_API_ENDPOINT}/${endpoint}`, "POST", body, isFormData, responseType, cancellationToken);
};

/**
 * DELETE request
 */
export const deleteRequest = async (endpoint, body, cancellationToken) => {
    return makeRequest(`${REACT_APP_API_ENDPOINT}/${endpoint}`, "DELETE", body, null, cancellationToken);
};