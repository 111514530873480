import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { TextValidator } from 'react-material-ui-form-validator';
import _ from 'lodash';

// Import Styles
import style from "./style.jsx";

const TextBoxComponent = (props) => {
	/**
	 * Define Props
	 */
	const { noOutline, classes, value, name, isRequired, onChange, onKeyDown, placeholder, valOnChange, ...rest } = props;

	/**
	 * Define States
	 */
	const [inputValue, setInputValue] = useState(value);

	/**
	 * Set Updated Value
	 */
	useEffect(() => {
		setInputValue(value);
	}, [value]);

	/**
	 * Define on change event using with debounce
	 */
	const handleOnBlur = (event) => {
		if (event.target.value !== value) {
			onChange(event);
		}
	};

	/**
	 * Handle On Change Event
	 * @param {*} event
	 */
	const handleChange = (event) => {
		setInputValue(event.target.value);
		if (valOnChange) {
			onChange(event);
		}
	};

	return (
		<Grid className={`${noOutline ? classes.outLineNone : null} ${noOutline ? 'noOutline' : ''} textBoxContainer`}>
			<TextValidator
				autoComplete={"off"}
				value={inputValue}
				onChange={handleChange}
				onBlur={handleOnBlur}
				onKeyDown={onKeyDown}
				name={name}
				required={isRequired}
				placeholder={placeholder}
				className={classes.textValidatorContainer}
				errorMessages={[`${name} is required`]}
				sx={{ width: "100%" }}
				{...rest}
			/>
		</Grid>
	);
};

/**
 * Define Props
 */
TextBoxComponent.propTypes = {
	onChange: PropTypes.func,
	classes: PropTypes.object,
	noOutline: PropTypes.bool,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	name: PropTypes.string,
	onKeyDown: PropTypes.func,
	isRequired: PropTypes.bool,
	valOnChange: PropTypes.bool
};

/**
 * Set Default Values
 */
TextBoxComponent.defaultProps = {
	onChange: () => { },
	classes: {},
	noOutline: false,
	value: '',
	valOnChange: false
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
	return _.isEqual(prevProps.value, nextProps.value) && _.isEqual(prevProps.classes, nextProps.classes);
}

export default withStyles(
	(theme) => ({
		...style(theme)
	}),
	{ withTheme: true }
)(TextBoxComponent);

/**
 * Export As Memo Component
 */
export const MemoTextBoxComponent = withStyles(
	(theme) => ({
		...style(theme)
	}),
	{ withTheme: true }
)(React.memo(TextBoxComponent, areEqual));