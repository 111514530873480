import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, Tooltip, IconButton, Grid, Checkbox, Typography } from '@mui/material';
import _ from 'lodash';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

// Import Image
import { DeleteIcon, LinkIcon, ColorDropIcon, MenuVerticleIcon, PlayIcon2, SyncIcon } from '../../assets/svg/index';

// Import Components
import { ChipComponent, SelectComponent, ValueEditorComponent, SwitchComponent, LoaderButtonComponent } from '../index';

// Import Helpers
import { numberWithCommas, convertUTCtoLocalDateTime } from '../../helpers/appHelpers';


const TableBodyCell = (props) => {

    /**
     * Define Props
     */
    const { classes, header, data, handleTableCell, parentDetail, onCompnentEvent, onClickActions, conditionDeleteShowColumn, selectComponentList, onHandleChipAdd, onHandleChipDelete, onHandleChipSelect, onCellClick } = props;

    /**
     * Handle cell click events
     * @param {*} key
     * @param {*} value
     */
    const onHandleCellClickEvent = (key, event) => {
        if (header.isAccordian) {
            handleTableCell(event, header);
        } else if (header.customFunction) {
            header.customFunction(data);
        } else {
            onCellClick(key, data, event, parentDetail);
        }
    };

    /**
     * Handle Component Events
     * @param {*} key
     * @param {*} value
     */
    const onHandleComponentEvent = (key, value, event) => {
        onCompnentEvent(key, value, data, event, parentDetail);
    };

    /**
     * Handle Delete Event
     */
    const handleActionsClick = (data, actionName, event) => {
        onClickActions(data, actionName, event, parentDetail);
    };

    /**
     * Bind Action for Cell
     * @param {*} actions
     * @returns
     */
    const bindCellActions = (actions = []) => {
        return (
            actions && actions.map((item, index) => {
                let action = null;
                const isDisabled = item.disableKey ? data[item.disableKey] : false;
                if (isDisabled) {
                    return false;
                }
                switch (item.type) {
                    case 'delete':
                        const showDeleteAction = conditionDeleteShowColumn ? data[conditionDeleteShowColumn] : true;
                        action = showDeleteAction ? <DeleteIcon key={`${item.key}-${index}`} /> : null;
                        break;
                    case 'delete-loader':
                        return (
                            <LoaderButtonComponent type="button" isCircleLoader isLoading={data.is_delete}>
                                <Tooltip title={"Delete"}>
                                    <IconButton onClick={(event) => handleActionsClick(data, item.type, event)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </LoaderButtonComponent>
                        );
                    case 'deploy':
                        return (
                            <LoaderButtonComponent type="button" key={`${item.type}-${index}`} isCircleLoader isLoading={data.is_deploying}>
                                <Tooltip title={"Deploy"}>
                                    <IconButton onClick={(event) => handleActionsClick(data, item.type, event)}>
                                        <PlayIcon2 />
                                    </IconButton>
                                </Tooltip>
                            </LoaderButtonComponent>
                        );
                    case 'link':
                        action = <LinkIcon key={`${item.type}-${index}`} />;
                        break;
                    case 'more':
                        action = <MenuVerticleIcon key={`${item.type}-${index}`} />;
                        break;
                    case 'sync':
                        return (
                            <LoaderButtonComponent type="button" key={`${item.type}-${index}`} isCircleLoader isLoading={data.is_sync}>
                                <Tooltip title={"Sync"}>
                                    <IconButton onClick={(event) => handleActionsClick(data, item.type, event)}>
                                        <SyncIcon />
                                    </IconButton>
                                </Tooltip>
                            </LoaderButtonComponent>
                        );
                    default:
                        action = null;
                        break;
                }
                if (!action) {
                    return null;
                }
                return (
                    <Tooltip key={`tooltip-${index}`} title={item.type} arrow>
                        <IconButton
                            key={`icon-${index}`}
                            aria-label="Link"
                            color="inherit"
                            className={'p5'}
                            onClick={(event) => handleActionsClick(data, item.key, event)}
                        >
                            {action}
                        </IconButton>
                    </Tooltip>);
            })
        );
    };

    /**
     * Format Cell Value
     * @param {*} data
     * @param {*} header
     * @returns
     */
    const formatCellData = (data, header) => {
        switch (header.type) {
            case 'number':
                return numberWithCommas(data[header.key]);
            case 'date':
                return convertUTCtoLocalDateTime(data[header.key]);
            default:
                return data[header.key] || "";
        }
    };

    /**
     * Bind Cell Component
     * @returns
     */
    const bindCellComponent = () => {
        let cellComponent = null;
        let isDisabled = header.disableKey ? data[header.disableKey] : false;
        if (header.isDisabled) {
            isDisabled = header.isDisabled(data, header);
        }
        if (header.isPermissionDisabled) {
            isDisabled = true;
        }
        switch (header.component) {
            case 'list':
                if (data[header.key]) {
                    cellComponent = <SelectComponent className={`${classes.outlineNone} outlineNone`} value={data[header.componentKey]} onSelectChange={(value) => onHandleComponentEvent(header.componentKey, value, data)} list={data[header.key] && data[header.key].split(',') || []} />;
                }
                break;
            case 'switch':
                cellComponent = <SwitchComponent size="small" disabled={isDisabled} checked={data[header.key] || false} handleChange={(value) => onHandleComponentEvent(header.key, value)} />;
                break;
            case 'chips':
                cellComponent = <ChipComponent chipClass={`${header.className}`}
                    data={data[header.key] ? data[header.key] : []}
                    labelKey={header.valueKey || ""}
                    limit={header.limit || 1}
                    editable={!header.isNotEditable && !header.isPermissionDisabled}
                    onChipAdd={(value) => onHandleChipAdd(value, data[header.key], data, header.key, parentDetail)}
                    onChipRemove={(selectedChip) => onHandleChipDelete(selectedChip, data[header.key], data, header.key, parentDetail)}
                    onChipSelect={(selectedChip, event) => onHandleChipSelect(selectedChip, header.key, data, event)}
                    add={!header.isPermissionDisabled && (header.addLimitCount && header.isAdd ? header.addLimitCount !== data[header.key].length : header.isAdd)}
                    disableInput={header.disableInput}
                    availableList={selectComponentList[header.key] || []}
                    addType={header.chipAddType || "text"}
                    handleChipEvent={(event, value) => onHandleComponentEvent(header.key, event, value, data)}
                    className={header.className}
                    haveColor={header.haveColor}
                    addLimit={header.addLimitCount || null}
                    isChipDelete={header.chipDelete}
                />;
                break;
            case 'subtagchips':
                const subChips = [...selectComponentList[header.key]];
                const index = subChips.findIndex((data) => data.id === parentDetail);
                if (index >= 0) {
                    subChips.splice(index, 1);
                }
                cellComponent = <ChipComponent chipClass={`${header.className}`}
                    data={data[header.key] ? data[header.key] : []}
                    labelKey={header.valueKey || ""}
                    limit={header.limit || 1}
                    editable={!header.isPermissionDisabled && !header.isNotEditable}
                    onChipAdd={(value) => onHandleChipAdd(value, data[header.key], data, header.key, parentDetail)}
                    onChipRemove={(selectedChip) => onHandleChipDelete(selectedChip, data[header.key], data, header.key, parentDetail)}
                    onChipSelect={(selectedChip, event) => onHandleChipSelect(selectedChip, header.key, data, event)}
                    add={!header.isPermissionDisabled && (header.addLimitCount && header.isAdd ? header.addLimitCount !== data[header.key].length : header.isAdd)}
                    disableInput={header.disableInput}
                    availableList={subChips || []}
                    addType={header.chipAddType || "text"}
                    handleChipEvent={(event, value) => onHandleComponentEvent(header.key, event, value, data)}
                    className={header.className}
                    haveColor={header.haveColor}
                    addLimit={header.addLimitCount || null}
                    isChipDelete={header.chipDelete}
                />;
                break;
            case 'textbox':
                cellComponent = <ValueEditorComponent
                    variant="standard"
                    label={header.componentLabel}
                    value={data[header.key] || ""}
                    type={header.datatype || data[header.componentKey] || "integer"}
                    onChange={(value) => onHandleComponentEvent(header.key, value)}
                    noOutline={header.inlineEdit}
                    placeholder={header.placeholder}
                    disabled={header.isPermissionDisabled} />;
                break;
            case 'checkbox':
                cellComponent = <Checkbox disabled={isDisabled} checked={data[header.key] || false} onClick={(event) => onHandleComponentEvent(header.key, !data[header.key], event)} icon={<CheckBoxOutlineBlankOutlinedIcon />} checkedIcon={<CheckBoxOutlinedIcon />} />;
                break;
            case 'color':
                cellComponent = (
                    <IconButton disableRipple className={classes.colorPicker}>
                        <ColorDropIcon dropColor={data[header.key]} />
                        {
                            !header.isPermissionDisabled &&
                            <input className="typeColor" type="color" onChange={(event) => onHandleComponentEvent(header.key, event)} />
                        }
                    </IconButton>);
                break;
            case 'select':
                cellComponent = <SelectComponent className={`${classes.outlineNone} outlineNone`} value={data[header.key]} onSelectChange={(value) => onHandleComponentEvent(header.key, value, data)} list={selectComponentList[header.key] || []} propertyName={header.componentKey} displayPropertyName={header.selectComponentKey} isDisabled={header.isPermissionDisabled} />;
                break;
            default:
                cellComponent = (
                    <Grid onClick={() => header.customFunction && header.customFunction(data)} style={{ width: '100%', cursor: header.clickable ? "pointer" : "" }}>
                        {formatCellData(data, header)}
                    </Grid>);
                break;
        }
        return header.tooltip ? (
            <Tooltip title={data[header.key] || ''} arrow style={{ cursor: header.clickable ? "pointer" : "" }}>
                {cellComponent}
            </Tooltip>
        ) : cellComponent;
    };

    const bindTableCell = () => {
        return header.key === "actions" && header.actions ? bindCellActions(header.actions) : bindCellComponent();
    };

    return (
        <TableCell onClick={(event) => onHandleCellClickEvent(header.key, event)} sx={{ width: header.width || '', textAlign: header.align || 'left' }}>
            <Typography variant="body1" className={`dflex ${header.removeTextTransform ? "" : "capitalize"} tableCell ${header.align && "justifyCenter"}`}>
                {bindTableCell()}
            </Typography>
        </TableCell>
    );
};


/**
 * Set Component Props
 */
TableBodyCell.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    conditionDeleteShowColumn: PropTypes.string,
    onHandleChipAdd: PropTypes.func,
    onHandleChipDelete: PropTypes.func,
    onHandleChipSelect: PropTypes.func,
    onClickActions: PropTypes.func,
    selectComponentList: PropTypes.object,
    header: PropTypes.object,
    onCompnentEvent: PropTypes.func,
    handleTableCell: PropTypes.func,
    onCellClick: PropTypes.func,
    parentDetail: PropTypes.string
};


/**
 * Set Default Values
 */
TableBodyCell.defaultProps = {
    classes: {},
    data: {},
    parentDetail: "",
    conditionDeleteShowColumn: "",
    onHandleChipAdd: () => { },
    onHandleChipDelete: () => { },
    onHandleChipSelect: () => { },
    onClickActions: () => { },
    selectComponentList: {},
    header: {},
    onCompnentEvent: () => { },
    handleTableCell: () => { },
    onCellClick: () => { }
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */
function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps.data, nextProps.data && prevProps.header && nextProps.header);
}

// export default TableBodyCell;

export default React.memo(TableBodyCell, areEqual);