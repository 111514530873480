// Default Imports
import React, { Fragment, useState, useMemo } from 'react';
import { Chip, IconButton, Stack, Typography, Backdrop, Grid, Box, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

// Import Styles
import style from "./style.jsx";

// Import Component
import ListChipItems from './components/list.jsx';
import AutoCompleteComponent from '../autoComplete/index.jsx';

// Import Images
import { PlusIcon } from '../../assets/svg/index.js';
import AcceptRejectIcon from './components/acceptRejectIcon.jsx';

const ChipComponent = (props) => {
    /**
     * Define Props
     */
    const { classes, className, chipClassName, dirction, spacing, data, labelKey, limit, editable, haveDataType, onChipAdd, onChipRemove,
        chipClass, add, onChipSelect, availableList, placeholder, handleChipEvent, filterCondition, addType, isrequired,
        disableInput, handleChipSelectionClose, haveColor, autoCompleteDataTypeOption, addLimit, isChipDelete, addText, stringChip,
        acceptActions, onHandleAcceptAction
    } = props;

    /**
     * Define State
     */
    const [anchorEl, setAnchorEl] = useState(null);
    const [isAddNewChip, setAddNewChip] = useState(false);
    const [autocompleteInput, setAutoCompleteInput] = useState("");
    const [chipValue, setChipValue] = useState("");

    /**
     * Handle Chip Delete
     * @param {*} chipId
     */
    const handleDelete = (chipId) => {
        if (!isChipDelete) {
            const listItems = [...data];
            const index = listItems.findIndex((data) => data.id === chipId.id);
            listItems.splice(index, 1);
            handleChipEvent(listItems);
        } else {
            onChipRemove(chipId);
        }
    };

    /**
     * Returns the lable value based on the given key
     * @param {*} item
     * @returns
     */
    const getLabel = (item) => {
        return labelKey ? item[labelKey] : item;
    };

    /**
     * Handle Add Button
     * @param {*} event
     */
    const handlePlusButton = (event) => {
        if (!disableInput) {
            setAddNewChip(true);
        }
        onChipSelect(null, event);
    };

    /**
     * Handle ChipValue Change
     * @param {*} event
     */
    const handleChange = (event) => {
        setChipValue(event.target.value);
    };

    /**
     * Handle OnChange
     * @param {*} selectedItem
     */
    const onChange = (selectedItem) => {
        if ((selectedItem.id) || (stringChip && selectedItem.trim())) {
            const listItems = [...data];
            listItems.push({ ...selectedItem });
            handleChipEvent(listItems);
            if (addLimit && addLimit === listItems.length) {
                setAddNewChip(false);
            }
        }
    };

    /**
     * Handle input KeyDown
     * @param {*} event
     * @returns
     */
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            const duplicatedValues = data.indexOf(
                event.target.value.trim()
            );

            if (duplicatedValues !== -1) {
                setChipValue("");
                return;
            }

            if (!event.target.value.replace(/\s/g, "").length) { return; }

            onChipAdd(event.target.value);
            setChipValue("");
            setAddNewChip(false);
        }
    };

    /**
     * FIlter Chip Values
     * @param {*} list
     * @param {*} filterCondition
     * @returns list
     */
    const filterList = (list = [], filterCondition = false) => {
        if (filterCondition) {
            list = list.filter((obj) => !obj.is_default);
        }
        return list;
    };

    /**
     * Filter Selection List
     * @param {*} list
     * @param {*} selectedValue
     * @returns {Array}
     */
    const filterSelectionAvailableList = (list = [], selectedValue = []) => {
        if ((selectedValue instanceof String)) {
            selectedValue = JSON.parse(selectedValue);
        }
        selectedValue = selectedValue.filter((obj) => obj).map((obj) => obj[labelKey]);
        return list.filter((obj) => !selectedValue.includes(obj[labelKey]));

    };

    /**
     * Get Color For Chip
     * @param {*} list
     * @param {*} id
     * @returns
     */
    const getColor = (item) => {
        if (item.color) {
            return item.color;
        }
        if (availableList.length) {
            const color = availableList.find(((i) => i.id === item.id))?.color ?? '';
            return color;
        }
        return '';
    };

    /**
     * Filter Using UseMemo
     */
    const list = useMemo(() => filterList(data, filterCondition), [data, filterCondition]);
    const filterAvailableList = useMemo(() => filterSelectionAvailableList(availableList, data), [availableList, data]);


    const renderInput = () => {
        switch (addType) {
            case 'autocomplete':
                return (
                    <Fragment>
                        <AutoCompleteComponent
                            variant="standard"
                            data={filterAvailableList || []}
                            onChange={(event, newValue) => onChange(newValue)}
                            placeholder={placeholder}
                            customOption
                            labelKey={labelKey}
                            getOptionLabel={
                                (option) => {
                                    if (option && option[labelKey]) {
                                        return option[labelKey];
                                    }
                                    return option;
                                }
                            }
                            fullWidth
                            disableClearable
                            style={{ zIndex: 1 }}
                            onInputChange={(event) => setAutoCompleteInput(event?.target?.value)}
                            onClose={() => handleChipSelectionClose()}
                            inputValue={autocompleteInput || ""}
                            renderOption={
                                (props, option) => (
                                    <Box component="li" className={"chipDropDownValue"} {...props}>
                                        {
                                            autoCompleteDataTypeOption &&
                                            <Typography
                                                variant="body2"
                                                className={classes.attributeTypeIcon}
                                            >
                                                {option.derived_type && option.derived_type.charAt(0) || 'T'}
                                            </Typography>
                                        }
                                        <Tooltip title={option[labelKey]} arrow>
                                            <Typography
                                                className={classes.chipDropDownValue}
                                                variant="body1">
                                                {option[labelKey]}
                                            </Typography>
                                        </Tooltip>
                                    </Box>
                                )
                            }
                        />
                        <Backdrop style={{ opacity: 0 }} open={isAddNewChip} onClick={() => setAddNewChip(false)} />
                    </Fragment>
                );
            default:
                return (
                    <Grid className={classes.outLineNone}>
                        <TextValidator autoComplete={"off"} required={isrequired} value={chipValue} onChange={(event) => handleChange(event)} onKeyDown={(event) => handleKeyDown(event)} />
                    </Grid>
                );
        }
    };

    return (
        <Grid className="chipContainer">
            <ValidatorForm onSubmit={() => { }}>
                <Stack
                    direction={dirction}
                    spacing={spacing}
                    className={`chips ${classes.chips} ${className}`}
                >
                    {
                        (addType === "text" ? !isAddNewChip : true) &&
                        <React.Fragment>
                            {
                                !haveDataType && list.slice(0, limit).map((item, index) => {
                                    return (
                                        <Tooltip title={getLabel(item)} arrow key={`chip-${index}`}>
                                            <Chip
                                                className={`${chipClass} ${chipClassName}`}
                                                deleteIcon={(acceptActions && !item.approval_id) ? <AcceptRejectIcon onHandleAction={(status) => onHandleAcceptAction(status, item)} /> : <CloseIcon />}
                                                key={`chip-${index}`}
                                                label={getLabel(item)}
                                                style={{ backgroundColor: haveColor ? `${getColor(item)}25` : '' }}
                                                sx={
                                                    {
                                                        '& .MuiChip-label': {
                                                            color: `${haveColor ? `${getColor(item)}` : ''}`
                                                        }
                                                    }
                                                }
                                                onClick={editable ? (event) => { onChipSelect(item, event); } : null}
                                                onDelete={editable || (acceptActions && !item.approval_id) ? () => { handleDelete(item); } : null} />
                                        </Tooltip>
                                    );
                                }
                                )
                            }
                            {
                                haveDataType && list.map((item, index) =>
                                    <Chip
                                        key={`chip-${index}`}
                                        deleteIcon={acceptActions ? <AcceptRejectIcon /> : <CloseIcon />}
                                        style={{ backgroundColor: haveColor ? getColor(item.id) : '' }}
                                        label={
                                            <Typography variant="body2">
                                                <span className={"dataType"}>
                                                    {item.derived_type && item.derived_type.charAt(0) || ''}
                                                </span>
                                                {' '}
                                                {getLabel(item)}
                                            </Typography>
                                        }
                                        onDelete={editable ? () => { handleDelete(item); } : null} />
                                )
                            }
                            {(data.length > limit) ? <Chip label={`+${data.length - limit}`} onClick={(event) => setAnchorEl(event.currentTarget)} /> : null}
                        </React.Fragment>
                    }

                    {
                        add && !isAddNewChip &&
                        <Grid className="dflex alignCenter">
                            <IconButton className="p0 plusIcon" onClick={(event) => handlePlusButton(event)}>
                                <PlusIcon />
                            </IconButton>
                            <Typography variant="body1" onClick={(event) => handlePlusButton(event)} className={`${classes.textSecondary} pt1 pl-1 pointer`}>
                                {addText}
                            </Typography>
                        </Grid>
                    }
                    {isAddNewChip && renderInput()}
                    {
                        Boolean(anchorEl) &&
                        <ListChipItems
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            editable={editable}
                            data={data}
                            labelKey={labelKey}
                            handleDelete={handleDelete}
                            onChipSelect={onChipSelect}
                            onClose={() => setAnchorEl(null)}
                            acceptActions
                            onHandleAcceptAction={(status, item) => onHandleAcceptAction(status, item)}
                        />
                    }
                </Stack>
            </ValidatorForm>
        </Grid>
    );
};


/**
 * Define Prop Types
 */
ChipComponent.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    chipClassName: PropTypes.string,
    labelKey: PropTypes.string,
    data: PropTypes.array,
    limit: PropTypes.number,
    editable: PropTypes.bool,
    dirction: PropTypes.string,
    chipClass: PropTypes.string,
    spacing: PropTypes.number,
    haveDataType: PropTypes.bool,
    onChipRemove: PropTypes.func,
    onChipAdd: PropTypes.func,
    add: PropTypes.bool,
    onChipSelect: PropTypes.func,
    availableList: PropTypes.array,
    placeholder: PropTypes.string,
    handleChipEvent: PropTypes.func,
    filterCondition: PropTypes.bool,
    addType: PropTypes.string,
    isrequired: PropTypes.bool,
    disableInput: PropTypes.bool,
    handleChipSelectionClose: PropTypes.func,
    haveColor: PropTypes.bool,
    disableCloseOnSelect: PropTypes.bool,
    autoCompleteDataTypeOption: PropTypes.bool,
    addLimit: PropTypes.number,
    isChipDelete: PropTypes.bool,
    addText: PropTypes.string,
    stringChip: PropTypes.bool,
    acceptActions: PropTypes.bool,
    onHandleAcceptAction: PropTypes.func
};

/**
 * Set Default Values
 */
ChipComponent.defaultProps = {
    classes: {},
    className: "",
    labelKey: "",
    data: [],
    limit: 10,
    editable: false,
    dirction: "row",
    spacing: 1,
    haveDataType: false,
    add: false,
    onChipAdd: () => { },
    onChipRemove: () => { },
    onChipSelect: () => { },
    availableList: [],
    placeholder: "",
    filterCondition: false,
    addType: "text",
    disableInput: false,
    handleChipSelectionClose: () => { },
    onHandleAcceptAction: () => { },
    haveColor: false,
    disableCloseOnSelect: true,
    autoCompleteDataTypeOption: false,
    addLimit: null,
    isChipDelete: false,
    stringChip: false,
    acceptActions: false
};

export default withStyles((theme) => ({
    ...style(theme)
}))(ChipComponent);