import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

// Import Reducers
import {
    loginRequest, loginSuccess, loginFailure, logoutSuccess, logoutRequest, logoutFailure, forgotPasswordRequest, forgotPasswordSuccess,
    forgotPasswordFailure, resetPasswordRequest, resetPasswordSuccess, resetPasswordFailure, changePasswordRequest, changePasswordSuccess, changePasswordFailure
} from '../reducer/authReducer';
import { displyAlert } from "../reducer/alertReducer";
import { navigate } from '../reducer/navigationReducer';

// Import Constants
import appConstants from "../../constants/appConstants";

// Import Services
import { authService } from '../service';


/**
 * Login Actions
 * @param {*} action$
 * @returns
 */
const login = (action$) => action$.pipe(
    ofType(loginRequest),
    mergeMap(({ payload }) => {
        return from(authService.login(payload)).pipe(
            concatMap((res) => {
                return of(loginSuccess(res), navigate({ path: 'home.root', state: {} }));
            }),
            catchError((error) => {
                return of(loginFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data?.detail ?? error.message }));
            })
        );
    })
);

/**
 * Forgot Password Actions
 * @param {*} action$
 * @returns
 */
const forgotPassword = (action$) => action$.pipe(
    ofType(forgotPasswordRequest),
    mergeMap(({ payload }) => {
        return from(authService.forgotPassword(payload)).pipe(
            concatMap((res) => of(forgotPasswordSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.mail }))),
            catchError((error) => of(forgotPasswordFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
        );
    })
);

/**
 * Reset Password Actions
 * @param {*} action$
 * @returns
 */
const resetPassword = (action$) => action$.pipe(
    ofType(resetPasswordRequest),
    mergeMap(({ payload }) => {
        return from(authService.resetPassword(payload)).pipe(
            concatMap((res) => of(resetPasswordSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }), navigate({ path: 'auth.login', state: {} }))),
            catchError((error) => of(resetPasswordFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
        );
    })
);

/**
 * Change Password Actions
 * @param {*} action$
 * @returns
 */
const changePassword = (action$) => action$.pipe(
    ofType(changePasswordRequest),
    mergeMap(({ payload }) => {
        return from(authService.changePassword(payload)).pipe(
            concatMap((res) => of(changePasswordSuccess(res), displyAlert({ 'type': 'success', 'message': appConstants.successMessages.updated }), navigate({ path: 'auth.login', state: {} }))),
            catchError((error) => of(changePasswordFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
        );
    })
);

/**
 * Logout Actions
 * @param {*} action$
 * @returns
 */
const logout = (action$) => action$.pipe(
    ofType(logoutRequest),
    mergeMap(({ payload }) => {
        return from(authService.logout(payload)).pipe(
            concatMap((res) => of(logoutSuccess(res))),
            catchError((error) => of(logoutFailure(), displyAlert({ 'type': 'error', 'message': error.response?.data ?? error.message })))
        );
    })
);

// Export All Auth Epic Functions
export const authEpic = [login, forgotPassword, resetPassword, logout, changePassword];