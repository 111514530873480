// Import API Helper
import { getRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Get ConnectionTypes
 * @returns
 */
async function getConnectionTypes() {
    const response = await getRequest(urlConstants.default.url);
    return response;
}


/**
 * Export All Service Functions
 */
export const defaultService = {
    getConnectionTypes
};