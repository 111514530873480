
const style = (theme) => ({
	dialog: {
		'& .MuiPaper-root': {
			width: '100%',
			maxWidth: '500px'
		},
		'& .actions': {
			padding: "8px 24px 24px",
			'& .cancelBtn': {
				background: theme.palette.greyshades.darkgrey
			}
		}
	}
});

export default style;