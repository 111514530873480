import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    path: null,
    state: {},
    params: []
};

const navigationSlice = createSlice({
    name: 'navigation',
    initialState: initialState,
    reducers: {
        navigate(state, action) {
            state.path = action.payload.path;
            state.state = action.payload.state || {};
            state.params = action.payload.params || [];
        },
        clear(state) {
            state.path = null;
            state.state = {};
            state.params = [];
        }
    }
});

export const { navigate, clear } = navigationSlice.actions;
export default navigationSlice.reducer;