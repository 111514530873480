const appConstants = {
    localStorageKey: 'dqlabs',
    header: {
        organization: 'Organizations'
    },
    labels: {
        authentication: {
            copyrights: '©2022 DQLabs, Inc. All rights reserved.',
            email: 'Email',
            name: "Name",
            password: 'Password',
            newPassword: 'New Password',
            firstName: 'First Name',
            lastName: 'Last Name',
            confirmPassword: 'Confirm Password',
            login: 'Login',
            submit: 'Submit',
            resetPassword: 'Reset Password',
            changePassword: 'Change Password',
            forgotPassword: 'Forgot Password',
            backtoLogin: 'Back to login',
            sso: 'SSO',
            signingFirstTime: 'Signing in for the first time ?',
            signInWithEmail: 'Sign in with your email',
            enterEmailId: 'Enter Email ID',
            sendMagicLink: 'Send Magic Link',
            magicLink: 'Magic Link',
            validEmailIdEntered:
                'If a valid email has been enterd, you will receive an email with a MagicLink.',
            linkToSignin: 'Please use that link to sign in.',
            ok: 'Ok'
        }
    },
    errorMessages: {
        invalidMail: 'Email is not valid',
        requriedField: 'is required',
        noresult: 'No results found',
        loading: 'Loading please wait..,',
        nameRequired: 'Name is required',
        valueRequired: 'Value is required',
        messageRequired: 'Message is required',
        passwordPolicy: 'Password requirement policy not met',
        isPasswordMatch: 'Password and Confirm Password Should Match',
        emailRequired: 'Email is required',
        phoneRequired: 'Phone number is required',
        domainName: 'Domain name is required',
        databaseRequired: 'Database name is required',
        airflowUrl: 'Airflow url is required',
        userName: 'Username is required',
        password: 'Password is required',
        accessKey: 'Access key is required',
        secretKey: 'Secreat key is required',
        regionName: 'Region name is required',
        EnvironmentName: 'Environment name is required',
        schema_name: "Schema name is required",
        host: "Host is required",
        port: "Port is required",
        instanceSize: "Instance size is required",
        clusterSize: "Cluster size is required"
    },
    successMessages: {
        updated: 'Updated Successfully',
        deleted: 'Deleted Successfully',
        deactivated: 'Deactivated Successfully',
        created: 'Created Successfully',
        copy: 'Copied Successfully',
        trigger: "Triggered Successfully",
        invited: 'Invited Successfully',
        shared: "Shared Successfully",
        saved: "Saved Successfully",
        mail: "Mail Sent Successfully",
        deploy: "Successfully Deployed.",
        sync: "Successfully Synced"
    },
    tableOptions: {
        common: [
            { type: 'search', customFunction: null },
            { type: 'download', customFunction: null },
            { type: 'columns', customFunction: null }
        ]
    },
    organziation: {
        basicDeatils: 'Basic Details',
        name: 'Organization Name',
        email: "Email",
        phone: "Phone",
        domain: "Domain",
        domainName: "Domain Name",
        database: "Database",
        databasename: "Databse Name",
        schema_name: "Schema Name",
        host: "Host",
        port: "Port",
        airflow: "Airflow",
        mwaaInstance: "MWAA Instance",
        airflowURL: "Airflow URL",
        userName: "User Name",
        password: "Password",
        accessKey: "Access Key",
        secretKey: "Secret Key",
        regionName: "Region",
        EnvironmentName: "Environment Name",
        cloud: 'Cloud',
        cancel: "Cancel",
        save: "Save",
        deploy: "Deploy",
        configuration: "Configuration",
        connectors: "Connectors",
        instanceSize: "Instance Size",
        clusterSize: "Cluster Size",
        airflowTypes: ["None", "MWAA", "Self Hosted"]
    },
    dialogBox: {
        delete: 'Confirm Delete',
        organizationDeleteMessage: 'Are you sure to delete this organization?',
        purge: 'Purge & Delete'
    }
};

export default appConstants;