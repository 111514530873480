import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    connectionTpes: []
};

const defaultSlice = createSlice({
    name: 'default',
    initialState: { ...initialState },
    reducers: {
        getConnectionTypeRequest() { },
        getConnectionTypeSuccess(state, action) {
            state.connectionTpes = action.payload || [];
        },
        getConnectionTypeFailure() { }
    }
});

export const { getConnectionTypeRequest, getConnectionTypeSuccess, getConnectionTypeFailure } = defaultSlice.actions;
export default defaultSlice.reducer;