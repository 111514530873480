import React from "react";
import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

// Import Private Layout
import { PrivateLayout } from "../layouts";

// Routes the page with authentication by the user
function PrivateRoute({ component, ...props }) {

  /**
   * Resux Select Action
   * @param {*} event
   */
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  return isLoggedIn ? <PrivateLayout component={component} {...props} /> : <Navigate to={{ pathname: "/", state: { from: props.location } }} />;
}

// Define Props Types
PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.string
};

export default PrivateRoute;