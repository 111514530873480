// Default Imports
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, IconButton, List, ListItem, ListItemIcon, ListItemText, Checkbox } from '@mui/material';
import { withStyles } from '@mui/styles';
// import _ from 'lodash';

// Import Svg
import { SearchIcon, DownloadIcon2, PlusIcon, ThreeColumnIcon } from '../../../../assets/svg/index';

// Import Component
import PopOverComponent from '../../../popOver/index.jsx';

//Import Styles
import style from "./style.jsx";
import { SelectComponent, SearchComponent } from '../../..';

const TableHeaderComponent = (props) => {

    /**
     * Define Props
     */
    const { options, title, headers, handleChange, classes, filters, description } = props;


    /**
     * Define State
     */
    const [anchorEl, setAnchorEl] = useState(null);
    const [columnSearch, setColumnSearch] = useState("");

    /**
     * Icon Actions
     * @param {*} type
     * @param {*} event
     */
    const iconActions = (item = {}, event = null) => {
        switch (item.type) {
            case 'search':
            case 'add':
                handleChange(item);
                break;
            case 'columns':
                setAnchorEl(event.currentTarget);
                break;
            default:
                break;
        }
    };


    /**
     * Bind Options
     * @returns Element
     */
    const bindOptions = () => {
        return (
            options && options.map((item, index) => {
                let action = null;
                switch (item.type) {
                    case 'search':
                        action = <SearchIcon />;
                        break;
                    case 'download':
                        action = <DownloadIcon2 />;
                        break;
                    case 'columns':
                        action = <ThreeColumnIcon />;
                        break;
                    case 'add':
                        action = <PlusIcon />;
                        break;
                    default:
                        action = null;
                        break;
                }
                return (
                    <IconButton
                        key={`icon-${index}`}
                        className={'p5'}
                        onClick={(event) => iconActions(item, event)}
                    >
                        {action}
                    </IconButton>);
            })
        );
    };

    const filteredHeaderArr = headers.filter((item) => item.name?.includes(columnSearch));

    return (
        <Fragment>
            <Grid container className="tableHeaderOptions pb5" alignItems={"center"} justifyContent="space-between">
                <Grid item>
                    {
                        title &&
                        <Typography component="h5" variant="h5" align="left" className="pb5">
                            {title}
                        </Typography>
                    }
                    {
                        description &&
                        <Typography variant="body1">
                            {description}
                        </Typography>
                    }
                </Grid>
                <Grid item className={classes.headerOption}>
                    {
                        filters && filters.map((filter, index) =>
                            <SelectComponent
                                className="mr-1"
                                key={`filter_${index}`}
                                value={filter.value || ""}
                                list={filter.options || []}
                                onSelectChange={(value) => filter.onChange(value)}
                                noOutline
                            />
                        )
                    }
                    {bindOptions()}
                </Grid>
            </Grid>
            {
                Boolean(anchorEl) &&
                <PopOverComponent
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    className={classes.poperComponent}
                >
                    <Grid className={classes.columnPopover}>
                        <List className={`${classes.columnList} columnList`}>
                            <ListItem className={classes.searchBoxColumn}>
                                <SearchComponent
                                    placeholder={"Search Columns"}
                                    value={columnSearch}
                                    onChange={(value) => setColumnSearch(value)} />
                            </ListItem>
                            <Grid className={classes.gridList}>
                                {
                                    filteredHeaderArr && filteredHeaderArr.map((header, index) =>
                                        <ListItem key={`header_${index}`}>
                                            <ListItemIcon>
                                                <Checkbox checked={header.showColumn} onChange={(event) => handleChange({ type: "columns" }, header.name, event.target.checked)} />
                                            </ListItemIcon>
                                            <ListItemText primary={header.name} />
                                        </ListItem>
                                    )
                                }
                            </Grid>
                        </List>
                    </Grid>
                </PopOverComponent>
            }
        </Fragment>
    );
};


/**
 * Define Component Props
 */
TableHeaderComponent.propTypes = {
    options: PropTypes.array,
    title: PropTypes.string,
    headers: PropTypes.array,
    handleChange: PropTypes.func,
    classes: PropTypes.object,
    filters: PropTypes.array,
    description: PropTypes.string
};

/**
 * Set Default Values
 */
TableHeaderComponent.defaultProps = {
    classes: {},
    options: [],
    headers: [],
    title: "",
    handleChange: () => { },
    filters: [],
    description: ""
};

export default withStyles((theme) => ({
    ...style(theme)
}))(TableHeaderComponent);