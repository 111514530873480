// Import API Helper
import { postRequest } from "../../helpers";

// Import Constants
import urlConstants from "../../constants/urlConstants";

/**
 * Login
 */
async function login(params) {
    const response = await postRequest(urlConstants.auth.login, params);
    return response;
}

/**
 * Forgot Password
 */
async function forgotPassword(params) {
    const response = await postRequest(urlConstants.auth.forgotPassword, params);
    return response;
}

/**
 * Reset Password
 */
async function resetPassword(params) {
    const response = await postRequest(urlConstants.auth.resetPassword, params);
    return response;
}

/**
 * Change Password
 */
async function changePassword(params) {
    const response = await postRequest(urlConstants.auth.changePassword, params);
    return response;
}

/**
 * Logout
 * @returns
 */
async function logout() {
    return 'success';
}

/**
 * Export All Authentication Service Functions
 */
export const authService = {
    login, forgotPassword, resetPassword, logout, changePassword
};