/* eslint-disable no-unused-vars */
import React from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';

//  Import Styles
import LayoutStyles from '../../layouts/style.jsx';
import OrganizationStyle from './style.jsx';

function Organization(props) {

    /**
     * Define Props
     */
    const { classes } = props;

    return (
        <Grid container className={classes.landinPageContainer}>
            <Outlet />
        </Grid>
    );
}

// default props
Organization.defaultProps = {
    classes: {}
};

// prop types
Organization.propTypes = {
    classes: PropTypes.object
};

export default withStyles(
    (theme) => ({
        ...OrganizationStyle(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(Organization);