import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Autocomplete } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';


// Import Styles
import Style from './style.jsx';
import LayoutStyles from '../../layouts/style.jsx';
import { ChevDownIcon } from '../../assets/svg/index.js';

function AutoCompleteComponent(props) {

    /**
     * Define Props
     */
    const { classes, selectedValue, data, placeholder, onChange, onClose, variant, labelKey, customOption,
        validators, errorMessages, onBlur, freeSolo, ...rest } = props;

    return (
        <Autocomplete
            id="attribute-search"
            clearOnEscape
            clearOnBlur
            freeSolo={freeSolo}
            popupIcon={<ChevDownIcon />}
            classes={
                {
                    option: classes.option
                }
            }
            className={classes.autoCompleteContainer}
            value={selectedValue || ""}
            options={
                customOption ? data : data.map((option) => (
                    {
                        label: option.name,
                        id: option.id,
                        datatype: option.derivedType ? option.derivedType.toLowerCase() : 'text',
                        values: option?.values || (option?.valueRequired === true ? [''] : [])
                    }
                ))
            }
            isOptionEqualToValue={
                (option, value) => {
                    const optionValue = labelKey ? option[labelKey] : option.label;
                    value = (value instanceof Object) ? value[labelKey] : value;
                    return value === optionValue;
                }
            }
            onChange={(event, newValue) => onChange(event, newValue)}
            onClose={() => onClose()}
            renderInput={
                (params) => (
                    <TextValidator
                        fullWidth
                        variant={variant}
                        validators={validators}
                        errorMessages={errorMessages}
                        value={selectedValue || ''}
                        {...params}
                        placeholder={placeholder}
                        onBlur={() => (onBlur ? onBlur() : null)}
                        {...rest}
                    />
                )
            }
            {...rest}
        />
    );
}


// default props
AutoCompleteComponent.defaultProps = {
    classes: {},
    selectedValue: "",
    variant: "standard",
    placeholder: "Select Attribute",
    data: [],
    onChange: () => { },
    onClose: () => { },
    labelKey: "",
    customOption: false,
    validators: [],
    errorMessages: [],
    onBlur: () => { },
    freeSolo: true
};

// prop types
AutoCompleteComponent.propTypes = {
    classes: PropTypes.object,
    selectedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    placeholder: PropTypes.string,
    variant: PropTypes.string,
    data: PropTypes.array,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    labelKey: PropTypes.string,
    customOption: PropTypes.bool,
    validators: PropTypes.array,
    errorMessages: PropTypes.array,
    onBlur: PropTypes.func,
    freeSolo: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...Style(theme),
        ...LayoutStyles(theme)
    }),
    { withTheme: true }
)(AutoCompleteComponent);