import { createTheme } from "@mui/material/styles";
import palette from "./palette";
import { typography } from "./typography";
import { components } from "./components";

export const getTheme = (cPalette = {}) => {
  const uPalete = { ...palette, ...cPalette };
  return createTheme({
    palette: uPalete,
    typography: typography(uPalete),
    components: components(uPalete)
  });
};