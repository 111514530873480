import { createSlice } from '@reduxjs/toolkit';

// Import App Constants
import appConstants from '../../constants/appConstants.js';

// Import Helper
import { getUser } from '../../helpers/appHelpers.js';

const loggedUser = getUser();

const initialState = {
    isLoading: false,
    isLoggedIn: Boolean(loggedUser),
    user: loggedUser ? loggedUser : {},
    permission: []
};

const authSlice = createSlice({
    name: 'auth',
    initialState: { ...initialState },
    reducers: {
        loginRequest(state) {
            state.isLoading = true;
            state.isLoggedIn = false;
        },
        loginSuccess(state, action) {
            localStorage.setItem(appConstants.localStorageKey, JSON.stringify(action.payload.data));
            state.isLoggedIn = true;
            state.user = action.payload.data && action.payload.data.user ? action.payload.data.user : {};
            state.isLoading = false;
        },
        updateAuthUserDetails(state, action) {
            state.user = action.payload?.data ?? state.user;
        },
        loginFailure(state) {
            state.isLoggedIn = false;
            localStorage.removeItem(appConstants.localStorageKey);
            state.isLoading = false;
        },
        logoutRequest(state) {
            state.isLoading = true;
        },
        logoutSuccess(state) {
            localStorage.removeItem(appConstants.localStorageKey);
            state.isLoggedIn = false;
            state.user = {};
            state.isLoading = false;
        },
        logoutFailure(state) {
            state.isLoading = false;
        },
        forgotPasswordRequest(state) {
            state.isLoading = true;
        },
        forgotPasswordSuccess(state) {
            state.isLoading = false;
        },
        forgotPasswordFailure(state) {
            state.isLoading = false;
        },
        resetPasswordRequest(state) {
            state.isLoading = true;
        },
        resetPasswordSuccess(state) {
            state.isLoading = false;
        },
        resetPasswordFailure(state) {
            state.isLoading = false;
        },
        changePasswordRequest(state) {
            state.isLoading = true;
        },
        changePasswordSuccess(state) {
            state.isLoading = false;
        },
        changePasswordFailure(state) {
            state.isLoading = false;
        }
    }
});

export const { loginRequest, loginSuccess, loginFailure, logoutRequest, logoutSuccess, logoutFailure, forgotPasswordRequest, forgotPasswordSuccess,
    forgotPasswordFailure, resetPasswordRequest, resetPasswordSuccess, resetPasswordFailure, changePasswordRequest, changePasswordSuccess, changePasswordFailure, updateAuthUserDetails } = authSlice.actions;
export default authSlice.reducer;