export const components = (palette) => {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    // fontSize: '15px',
                    borderRadius: 4
                },
                containedSizeSmall: {
                    // fontSize: '14px',
                    height: 30
                },
                outlinedSizeSmall: {
                    // fontSize: '14px',
                    height: 30
                },
                outlinedSizeMedium: {
                    height: 34,
                    border: `2px solid ${palette.secondary.main} !important`,
                    fontSize: 13,
                    fontWeight: 400
                },
                containedSizeLarge: {
                    height: 40
                },
                outlinedPrimary: {
                    fontFamily: palette.headers.primaryButton.fontFamily,
                    fontSize: palette.headers.primaryButton.size,
                    fontWeight: palette.headers.primaryButton.weight,
                    fontStyle: palette.headers.primaryButton.style,
                    textDecoration: palette.headers.primaryButton.textDecoration,
                    transform: palette.headers.primaryButton.transform
                },
                containedPrimary: {
                    fontFamily: palette.headers.primaryButton.fontFamily,
      fontSize: palette.headers.primaryButton.size,
      fontWeight: palette.headers.primaryButton.weight,
      fontStyle: palette.headers.primaryButton.style,
      textDecoration: palette.headers.primaryButton.textDecoration,
      transform: palette.headers.primaryButton.transform
                },
                containedSecondary: {
                    background: palette.greyshades.darkgrey,
                    fontFamily: palette.headers.secondaryButton.fontFamily,
      fontSize: palette.headers.secondaryButton.size,
      fontWeight: palette.headers.secondaryButton.weight,
      fontStyle: palette.headers.secondaryButton.style,
      textDecoration: palette.headers.secondaryButton.textDecoration,
      transform: palette.headers.secondaryButton.transform,
                    '&:hover': {
                        backgroundColor: palette.secondary.main
                    }
                },
                textSizeMedium: {
                    color: palette.headers.body1.color,
                    textTransform: 'capitalize',
                    '&:hover': {
                        color: palette.secondary.main
                    }
                },
                textSecondary: {
                    color: `${palette.secondary.main} !important`
                },
                text: {
                    fontFamily: palette.headers.textLink.fontFamily,
      fontSize: palette.headers.textLink.size,
      fontWeight: palette.headers.textLink.weight,
      fontStyle: palette.headers.textLink.style,
      textDecoration: palette.headers.textLink.textDecoration,
      transform: palette.headers.textLink.transform
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontSize: '2rem',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 2,
                        '& fieldset': {
                            borderColor: palette.greyshades.grey
                        }
                    },
                    '& .MuiOutlinedInput-input': {
                        height: '1.1rem !important'
                    },
                    '& .Mui-focused fieldset': {
                        borderColor: `${palette.secondary.main} !important`
                    },
                    '& input': {
                        fontFamily: palette.textFont,
                        '&::placeholder': {
                            color: `${palette.greyshades.darkgrey} !important`,
                            opacity: 1
                        }
                    },
                    '& .MuiInput-underline': {
                        '&:before': {
                            borderBottom: `1px solid ${palette.greyshades.grey}`
                        },
                        '&:after': {
                            borderBottom: `2px solid ${palette.secondary.main}`
                        },
                        '& .MuiInput-input': {
                            fontSize: 15
                        },
                        '&:hover:not(.Mui-disabled):before': {
                            borderBottom: '2px solid rgb(193 193 193 / 87%) !important'
                        }
                    },
                    '& .Mui-focused.MuiInputLabel-root': {
                        color: palette.secondary.main
                    },
                    '& .MuiInputLabel-shrink': {
                        fontSize: 14,
                        transform: 'translate(0, -6.5px) scale(1)'
                    },
                    '& .MuiInputLabel-root': {
                        color: palette.greyshades.darkgrey
                    }
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&.MuiInput-underline': {
                        '&:before': {
                            borderBottom: `1px solid ${palette.greyshades.grey}`
                        },
                        '&:after': {
                            borderBottom: `2px solid ${palette.secondary.main}`
                        },
                        '& .MuiInput-input': {
                            fontSize: 16
                        }
                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginTop: 0,
                    height: 0,
                    textAlign: 'right',
                    marginRight: '1px',
                    fontSize: '13px'
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    '& .Mui-checked': {
                        color: `${palette.secondary.main} !important`
                    },
                    '& .Mui-checked+.MuiSwitch-track': {
                        backgroundColor: `${palette.secondary.main} !important`,
                        opacity: '0.4 !important'
                    },
                    '& .MuiSwitch-track': {
                        backgroundColor: `${palette.greyshades.darkgrey} !important`
                    },
                    '& .Mui-disabled+.MuiSwitch-track': {
                        opacity: '0.28 !important'
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: `${palette.secondary.main} !important`
                    }
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    fontSize: '12px !important'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '&.MuiFormControlLabel-root': {
                        marginLeft: '-5px !important'
                    },
                    '& .MuiTypography-root': {
                        paddingLeft: 6
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        background: `${palette.secondary.main}15 !important`,
                        '&:hover': {
                            background: `${palette.secondary.main}15`
                        }
                    },
                    '&:hover': {
                        color: `${palette.secondary.main} !important`
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: palette.secondary.main,
                    cursor: 'pointer'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    body1: {
                        fontSize: palette.headers.body1.size
                    }
                }
            }
        },
        MuiRating: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem',
                    color: '#F6C831',
                    sizeSmall: {
                        fontSize: '1.4rem'
                    }
                }
            }
        },
        MuiDatePicker: {
            styleOverrides: {
                root: {
                    '& .Mui-selected': {
                        backgroundColor: 'rgb(25, 118, 210) !important'
                    }
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                root: {
            '& .MuiPopover-paper': {
                boxShadow: '0px 0px 14px rgb(237 237 237 / 90%) !important',
                borderRadius: 3
            }
        }
    }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
            '& .MuiListItemButton-root:hover .MuiTypography-root': {
                color: `${palette.secondary.main} !important`
            }
        }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-option': {
                        '&:hover': {
                            color: `${palette.secondary.main} !important`
                        }
                    }
                }
            }
        }
    };
};