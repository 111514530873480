const style = (theme) => ({
    selectComponent: {
        '& .ChevDownIcon': {
            width: 12,
            height: 12,
            flexShrink: 0
        },
        '& .Mui-focused': {
            color: `${theme.palette.secondary.main} !important`
        },
        '& .MuiInputLabel-root': {
            transform: 'translate(0, 19px) scale(1)',
            color: `${theme.palette.greyshades.darkgrey} !important`
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(0, -6.5px) scale(1)',
            fontSize: '14px'
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent !important'
        },
        '& .MuiInput-underline:before': {
            borderColor: `${theme.palette.greyshades.lightgrey} !important`
        },
        '& .MuiInputBase-root': {
            marginTop: 16
        }
    },
    outLineNone: {
        '& .MuiSelect-select': {
            paddingTop: '4.5px !important',
            paddingBottom: '4.5px !important',
            paddingRight: '32px !important',
            paddingLeft: '0px !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
            borderBottom: `1px solid ${theme.palette.greyshades.grey} !important`
        },
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderBottom: `1px solid ${theme.palette.greyshades.grey} !important`,
                borderRadius: 0
            }
        }
    }
});
export default style;