const AuthenticationStyles = () => ({
    //   Login
    loginBox: {
        background: '#fff',
        padding: '95px 75px 110px',
        position: 'fixed',
        zIndex: '1',
        boxShadow: '0px 15px 40px #7B848C33',
        top: '50%',
        left: '72%',
        transform: 'translate(-40%, -50%)',
        maxWidth: '500px',
        width: '100%',
        minHeight: 576,
        '@media only screen and (max-width: 1600px)': {
            '&.MuiGrid-root': {
                maxWidth: '460px',
                padding: '48px 58px',
                minHeight: '500px'
            }
        },
        '@media only screen and (max-width: 1399px)': {
            '&.MuiGrid-root': {
                padding: '30px 50px',
                maxWidth: '400px',
                minHeight: '450px',
                top: '48%'
            },
            '& .MuiGrid-root>.MuiGrid-item': {
                paddingTop: '26px',
                '&:first-child': {
                    marginTop: '16px'
                }
            }
        }
    },
    loginLogo: {
        marginBottom: '30px'
    }

});
export default AuthenticationStyles;